import NewAssessmentStep from "./NewAssessmentStep";

const AssessmentSteps = (props) => {
    return (
        <>
            <NewAssessmentStep content={"Assign Hotels"} />
            <br />
            <NewAssessmentStep
                content={"Create New Section"}
                goto={"/create-assessments-step"}
            />
        </>
    );
};

export default AssessmentSteps;
