import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router'
import Page, { useMenu } from '../components/Page'
import c from "./Risks.module.scss"
import RiskProfileChart from '../components/RiskProfileChart'
import RiskMaturityChart from '../components/RiskMaturityChart'
import HotelProfileScoreBreakdown from "../components/HotelProfileScoreBreakdown"
import axios from 'axios'
import { getRiskPercentage } from "aius-fn/riskCalculator"

const EACH_MAX_RISK = 4
const MAX_RISK = 36

const Risks = (props) => {
  const [openMenu, controls] = useMenu()
  const [risks, setRisks] = useState({})
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations/risks/${id}`)
      .then(res => setRisks(res.data.content))
      .catch(e => console.warn(e))
  }, [id])

  return (
    <Page {...controls}>
      <Page.Top back={true} onBack={() => {history.goBack()}} onMenuOpen={openMenu}><div className={c.avaterContainer}>
          <div className={c.avatar}>
            <img className={c.image} src={risks?.property?.coverImageURL} alt="" />
          </div>
          <div>{risks?.property?.name}</div>
        </div></Page.Top>
      <div className={c.alerts}>
        <Page.Section>
        
          <h3 className='mb-2'>Risk Maturity Detail</h3>
          <div className={c.imageContainer}>
            <RiskProfileChart 
              value={getRiskPercentage(risks?.catastrophe_risk_score?.score, EACH_MAX_RISK)} 
              color={risks?.catastrophe_risk_score?.color}
            />
            <p className={c.description}><strong>Catastrophe Level: {risks?.catastrophe_risk_score?.name}</strong><br/>{risks?.catastrophe_risk_score?.description}</p>
          </div>

          <div className={c.imageContainer}>
            <RiskProfileChart 
              value={getRiskPercentage(risks?.political_risk_score?.score, EACH_MAX_RISK)}
              color={risks?.political_risk_score?.color}
            />
            <p className={c.description}><strong>Political Level: {risks?.political_risk_score?.name}</strong><br/>{risks?.political_risk_score?.description}</p>
          </div>

          <div className={c.imageContainer}>
            <RiskProfileChart 
              value={getRiskPercentage(risks?.risk_factor?.score, EACH_MAX_RISK)} 
              color={risks?.risk_factor?.color}
            />
            <p className={c.description}><strong>Profile Level: {risks?.risk_factor?.name}</strong><br/>{risks?.risk_factor?.description}</p>
          </div>

          <div className={c.imageContainer}>
            <RiskProfileChart 
              value={getRiskPercentage(risks?.social_risk_score?.score, EACH_MAX_RISK)}
              color={risks?.social_risk_score?.color}
            />
            <p className={c.description}><strong>Social Level: {risks?.social_risk_score?.name}</strong><br/>{risks?.social_risk_score?.description}</p>
          </div>
          
        </Page.Section>
      </div>

      {/* Incident Breakdown */}
      <Page.Section>
        <div className='d-flex justify-content-center w-100 pb-5'>
        </div>
      </Page.Section>

      
      <div className={c.alerts}>
        <Page.Section>
          <h3>AIUS RISK MATURITY RATING&trade;</h3>
          <div className={c.imageContainer}>
            <RiskProfileChart 
              value={getRiskPercentage(risks?.risk_rating_value, MAX_RISK)}
              color={risks?.risk_rating?.color}
            />
            <p className={c.label}>{risks.risk_rating_value}</p>
            <p className={c.description}><strong>{risks?.risk_rating?.name}</strong><br/>This hotel currently has a {risks?.risk_rating?.name} Risk Rating</p>
          </div>

          <div className='d-flex justify-content-center w-100 pb-5'>
          <HotelProfileScoreBreakdown
            catastrophe={risks?.catastrophe_risk_score?.score}
            political={risks?.political_risk_score?.score}
            profile={risks?.risk_factor?.score}
            social={risks?.social_risk_score?.score}
            colors={[
              risks?.catastrophe_risk_score?.color,
              risks?.political_risk_score?.color,
              risks?.risk_factor?.color,
              risks?.social_risk_score?.color
            ]}
          />
        </div>
        </Page.Section>
      </div>

      {/* Score Changes */}
      <Page.Section>
        <div className='d-flex justify-content-center w-100 pb-5'>
        </div>
      </Page.Section>
            
      <div className={c.alerts}>
        <Page.Section>
          <h3>Historic Risk Score Updates</h3>
          { 
            risks.history?.map( risk => {
              const formatScore = function(newScore, oldScore){
                  let scoreChange = newScore - oldScore
                  if (scoreChange < 0){
                    return <>&darr; {scoreChange}</>  
                  } else if (scoreChange > 0){
                    return <>&uarr; {scoreChange}</>  
                  } else {
                    return <>&uarr;&darr; {scoreChange}</>  
                  }

                  
              }

              return (
              <div className={c.imageContainer} key={risk.id}>
                <RiskMaturityChart value={risk.old_score}/>
                <div className={c.label}>{risk.old_score}</div>
                <p className={c.description}>
                {formatScore(risk.new_score, risk.old_score)}<br/>
                {risk.created_at}<br/>
                {risk.name}</p>
              </div>)
            }) 
          }
          
        </Page.Section>
      </div>

    </Page>
  )
}

export default Risks