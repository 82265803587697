import { combineReducers } from "redux"
import workflows from "./workflows"
import hotels from "./hotels"
import user from "./user"
import reports from "./reports"
import system from "./system"

export const rootReducer = combineReducers({ user, hotels, workflows, reports, system })

export default rootReducer
