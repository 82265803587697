import React from 'react'
import ModalImage from "react-modal-image";
import PropTypes from "prop-types";
import FileIcon from 'aius-comp/FileIcon'
import { bytesToSize } from 'aius-fn'
import cn from 'classnames'
import c from './FileInfo.module.scss'

/**
    `import FileInfo from 'aius-comp/FileInfo'`

  - Displays information about file
  - If mimeType is 'image', then image is displayed, otherwise icon
 */

const FileInfo = props => {
  const { mimeType, url, fileName, fileSize, info } = props
  
  const icon = 'image' === props.mimeType.split('/')[0] ? (
    <ModalImage 
      small={url} 
      large={url} 
      showRotate={true}
      className={c.image}
    />
  ) : (
    <a href={url} rel="noreferrer" target="_blank">
      <FileIcon
        mimeType={mimeType}
        size="normal"
      />
    </a>
  )

  const fileClasses = cn([c.file, props.className])

  return (
    <div className={fileClasses}>
      <div className={c.icon}>{icon}</div>
      <div className='d-flex flex-column'>
        <h5 className={c.name}>{fileName}</h5>
        {info ? <span className={c.info}>{info}</span> : null}
        <span className={c.size}>{bytesToSize(fileSize ?? 0)}</span>
      </div>
    </div>
  )
}

FileInfo.defaultProps = {
  fileName: "undefined",
  fileSize: 0,
  mimeType: "empty",
  url: "",
  info: ""
}

FileInfo.propTypes = {
  fileName: PropTypes.string,
  fileSize: PropTypes.number,
  mimeType: PropTypes.string,
  url: PropTypes.string,
  info: PropTypes.string
}

export default FileInfo
