import React from 'react'
import ProgressTaskItem from './ProgressTaskItem'
import { FcCheckmark, FcHighPriority } from "react-icons/fc";
import { IoPlay } from "react-icons/io5";
import c from "./TaskListItem.module.scss"


const TaskListItem = ({ description, isPassed = true, ...rest }) => {
  const isCompleted = rest.current && rest.max && rest.current === rest.max
  return (
    <ProgressTaskItem
      {...rest}
      description={ isCompleted ? isPassed ? <FcCheckmark /> : <FcHighPriority /> : <IoPlay className={c.playIcon}/>}
    />
  )
}

export default TaskListItem
