import React, { useEffect, useRef } from "react"
import { Chart, PieController, ArcElement } from "chart.js"
import c from "./IncidentPieChart.module.scss"

/*
  Pie chart used for incident analytics
*/

Chart.register(PieController, ArcElement)

const IncidentPieChart = ({ minor, major, severe, crisis }) => {
  const chartRef = useRef()
  const empty = (minor + major + severe + crisis) === 0 ? [1]: [0]

  useEffect(() => {
    const ref = chartRef.current.getContext("2d")
    const data = {
      labels: ["Crisis", "Severe", "Medium", "Low"],
      datasets: [
        {
          label: "Incidents",
          data: [minor, major, severe, crisis, empty],
          backgroundColor: ["#71ADBB", "#E0B259", "#e37bde", "#FF4A4A" , '#F2F2F2'],
        },
      ],
    }
    const chart = new Chart(ref, {
      type: "pie",
      data: data,
      options: {
        responsive: true,
      },
    })
    return () => chart.destroy()
  }, [chartRef, minor, major, severe, crisis]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={c.chart}>
      <canvas ref={chartRef}></canvas>
      <div className={c.middle}></div>
    </div>
  )
}

export default IncidentPieChart
