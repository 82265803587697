import React, { useState, useEffect } from "react"
import { useHistory, useParams } from "react-router"
import Page, { useMenu } from '../components/Page'
import IconItem from "../components/IconItem"
import phoneIcon from "../images/icons/phone.svg"
import emailIcon from "../images/icons/email.svg"
import linkIcon from "../images/icons/link.svg"
import ErrorPage from "aius-comp/ErrorPage"
import Loading from "aius-comp/Loading"
import LocationDetailListItem from "../components/LocationDetailListItem"
import { capitalise } from "aius-fn"
import Button from "aius-comp/Button"
import axios from "axios"
import c from "./HotelDetails.module.scss"
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"


const HotelDetails = () => {
  const [openMenu, controls] = useMenu()
  const history = useHistory()
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(true)
  const [hotel, setHotel] = useState()
  const { id } = useParams()
  const GMAP_KEY = process.env.REACT_APP_GMAPS_API_KEY
  const backPath = history.location.state?.backPath ?? '/hotels'

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations/${id}`)
      .then(res => {
        const hotel = res.data?.location
        setHotel(hotel)
      })
      .catch(e => {
        console.error(e)
        setError(true)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [id])

  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  if (error) {
    console.error("Couldn't retrieve hotel information")
    return <ErrorPage title='Unexpected error' description="Couldn't retrieve hotel information" />
  }

  if (!hotel) {
    console.warn("Couldn't retrieve hotel information")
    return <ErrorPage title='No hotel' description="Hotel information wasn't found" />
  }

  // Aggregate hero details by category
  const details = Array.isArray(hotel.details) 
    ? (
        hotel.details.reduce((acc, detail) => {
        if (detail.schema.hero) {
          const { schema, value } = detail
          const { category, ...rest } = schema
  
          if (!acc[category]) {
            acc[category] = []
          }
          acc[category].push({ value, ...rest })
        }
        return acc
      }, {}) 
    )
    : {}
  
  const displayEnum = value => capitalise(value.split('_').join(' ').toLowerCase(), true)

  const mapStyles = {
    width: "100%",
    height: "100%",
  }

  const hotelCoordinates = {
    lat: hotel.latitude,
    lng: hotel.longitude,
  }

  const mapOptions = {
    disableDefaultUI: true,
  }

  return (
    <Page {...controls} active={true}>
      <Page.Top back onMenuOpen={openMenu} onBack={() => history.push(backPath)}>
        {hotel.name}
      </Page.Top>

      {/* Map */}
      <div className={c.location}>
        <Page.Section>
          <span>{hotel.name}</span>
        </Page.Section>
        <div className={c.map}>
          <LoadScript googleMapsApiKey={GMAP_KEY}>
            <GoogleMap mapContainerStyle={mapStyles} center={hotelCoordinates} zoom={12} options={mapOptions}>
              <>
                <Marker position={hotelCoordinates}>{hotel.name}</Marker>
              </>
            </GoogleMap>
          </LoadScript>
        </div>
      </div>


      {/* Controls */}
      <div className={c.controls}>
        <div className='d-flex flex-gap-2'>
          <Button size='small' variant='info' onClick={() => history.push("/risks/" + hotel.id)}>
            View Risk Report
          </Button>
        </div>
      </div>

      {/* Address */}
      <Page.Section>
        <div className={c.address}>
          <span>{hotel.address}</span>
          <span>{hotel.city}</span>
          <span>{hotel.postcode}</span>
        </div>
      </Page.Section>

      {/* Contact */}
      <Page.Section>
        <div className={c.contact}>
          <div className={c.info}>
            <img className={c.icon} src={phoneIcon} alt=''/>
            <span>
              <a href={`tel:${hotel.phoneNumber}`}>{hotel.phoneNumber}</a>
            </span>
          </div>
          <div className={c.info}>
            <img className={c.icon} src={linkIcon} alt=''/>
            <span>
              <a href={`${hotel.website}`} target='_blank' rel="noreferrer">
                {hotel.website}
              </a>
            </span>
          </div>
          <div className={c.info}>
            <img className={c.icon} src={emailIcon} alt=''/>
            <span>
              <a href={`mailto:${hotel.email}`}>{hotel.email}</a>
            </span>
          </div>
        </div>
      </Page.Section>

      {/* Users */}
      <Page.Section>
        <div className={c.users}>
          <div className={c.title}>All</div>
          <div className={c.list}>
            {Array.isArray(hotel.users) &&
              hotel.users.map(user => (
                <IconItem id={user.id} key={user.id} text={`${user.firstName} ${user.lastName}`} icon={user.profileImageURL} />
              ))}
          </div>
        </div>
      </Page.Section>

      {/* Details */}
      <div className="mt-5 mb-5">
        {Object.keys(details).map(category => (
          <div key={category}>
            <Page.Section>
              <div>{displayEnum(category)}</div>
            </Page.Section>
            <Page.Hr />
            <Page.Section>
              {details[category]?.map(detail => (
                <LocationDetailListItem
                  key={detail.id}
                  value={detail.value}
                  label={detail.label}
                  type={detail.type}
                  icon={detail.iconURL}
                  order={detail.order}
                />
              ))}
            </Page.Section>
          </div>
        ))}
      </div>

      {/* Controls */}
      <Page.Section>
        <div className='d-flex justify-content-between pb-4'>
          <Button onClick={() => history.push(`/hotels/${id}/all-details`, { hotel })}>
            All Details
          </Button>
          <Button onClick={() => history.push(`/hotels/${id}/edit`, { hotel })} variant='grey'>
            Edit Hotel
          </Button>
        </div>
      </Page.Section>
    </Page>
  )
}


export default HotelDetails