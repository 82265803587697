import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Hexagon from '../images/icons/dashboard_hexagon.svg'
import TaskListItem from './TaskListItem'
import DraggableBase from "./DraggableBase"
import DraggableTouch from './DraggableTouch'
import { useHistory } from 'react-router'
import axios from 'axios'
import ListItem from './ListItem'
import BaseTaskItem from './BaseTaskItem'
import c from './TasksToday.module.scss'

/*
  Draggable component showing tasks on hub page
*/

const TasksToday = ({ alertTasks, userID }) => {
  const history = useHistory()
  const [assessments, setAssessments] = useState([])
  const [trainings, setTrainings] = useState([])
  const [tasks, setTasks] = useState([])
  const [incompleteIncidents, setIncompleteIncidents] = useState([])
  const [incidents, setIncidents] = useState([])
  const [numberOfTasks, setNumberOfTasks] = useState(0);

  // Get assessments
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/assessments`)
      .then(res => setAssessments(res.data.assessments))
      .catch(e => console.warn(e.response?.data?.message))
  }, [])

  // Get trainings
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/trainings`)
      .then(res => setTrainings(res.data.trainings))
      .catch(e => console.warn(e.response?.data?.message))
  }, [])

  // Get Tasks
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/tasks`)
      .then(res => setTasks(res.data.tasks))
      .catch(e => console.warn(e.response?.data?.message))
  }, [])

  // Get incomplete reports
  useEffect(() => {
    const data = {
      params: {
        days: 7,
        state: "INPROGRESS",
        reporterID: userID,
        useCreatedAt: true,
      },
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents`, data)
      .then(res => setIncompleteIncidents(res.data.incidents))
  }, [userID])

  // Get finished reports
  useEffect(() => {
    const data = {
      params: {
        days: 7,
        state: "CLOSED",
        useCreatedAt: true,
      },
    }
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents`, data)
      .then(res => setIncidents(res.data.incidents))
  }, [])


  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs-todo`)
      .then(res => setNumberOfTasks(res.data.todoCount))
  })

  return (
    <DraggableBase>
      {/* Header */}
      <section className={c.header}>
        {/* Hexagon */}
        <div className={c.hexagon}>
          <img src={Hexagon} className={c.image} alt=''/>
          <p className={c.statistic}>
            <span className={c.fraction}>{numberOfTasks}</span>
          </p>
        </div>
        {/* Title */}
        <div className='mt-2'>
          <h2>My Work</h2>
        </div>
      </section>

      {/* Assessment Lists */}
      <section className={c.list}>
        {assessments?.length > 0 && <ListItem><h1>Assessments</h1></ListItem>}
        {assessments.map(assessment => {
          if (assessment.started) {
            return (
              <DraggableTouch key={assessment.runID} onClick={() => history.push(`/assessments/${assessment.runID}/progress`)}>
                <TaskListItem
                  title={assessment.name}
                  subtitle={`${assessment.completedSteps} / ${assessment.totalSteps} sections`}
                  max={assessment.totalSteps}
                  current={assessment.completedSteps}
                  isPassed={assessment.isPassed}
                />
              </DraggableTouch>
            )
          } else {
            return (
              <DraggableTouch key={assessment.id} onClick={() => history.push(`/assessments/${assessment.id}`)}>
                <TaskListItem title={assessment.name} subtitle={""} max={1} current={0} />
              </DraggableTouch>
            )
          }
        })}
      </section>

      {/* Alert Task Lists */}
      <section className={c.list}>
        {alertTasks?.length > 0 && <ListItem><h1>Alerts</h1></ListItem>}
        {alertTasks?.map(alert => (
          <DraggableTouch
            key={alert.id}
            onClick={() => history.push(`/alert-action/${alert.action.id}`, { notificationID: alert.id })}
          >
            <BaseTaskItem title={alert.action.name} subtitle={"Action required"} />
          </DraggableTouch>
        ))}
      </section>

      {/* Incomplete Incident List */}
      <section className={c.list}>
        {incompleteIncidents?.length > 0 && <ListItem><h1>Incomplete Reports</h1></ListItem>}
        {incompleteIncidents.map(incident => (
          <DraggableTouch key={incident?.id} onClick={() => history.push(`/reports/${incident?.id}`)}>
            <BaseTaskItem title={incident?.workflow?.name} subtitle={incident?.title} />
          </DraggableTouch>
        ))}
      </section>

      {/* Submitted Incidents (7 Days) */}
      <section className={c.list}>
        {incidents?.length > 0 && <ListItem><h1>Reports</h1></ListItem>}
        {incidents.map(incident => (
          <DraggableTouch key={incident?.id} onClick={() => history.push(`/reports/${incident?.id}`)}>
            <BaseTaskItem title={incident?.workflow?.name} subtitle={incident?.title} />
          </DraggableTouch>
        ))}
      </section>



      <section className={c.list}>
        {trainings?.length > 0 && <ListItem><h1>Training</h1></ListItem>}
        {trainings.map(training => {
          if (training.started) {
            return (
              <DraggableTouch key={training.runID} onClick={() => history.push(`/trainings/${training.runID}/progress`)}>
                <TaskListItem
                  title={training.name}
                  subtitle={`${training.completedSteps} / ${training.totalSteps} sections`}
                  max={training.totalSteps}
                  current={training.completedSteps}
                />
              </DraggableTouch>
            )
          }
          return (<></>)
        })}
      </section>

      <section className={c.list}>
        {tasks?.length > 0 && <ListItem><h1>Tasks</h1></ListItem>}
        {tasks.map(task => {
          if (task.started) {
            return (
              <DraggableTouch key={task.runID} onClick={() => history.push(`/tasks/${task.runID}/progress`)}>
                <TaskListItem
                  title={task.name}
                  subtitle={`${task.completedSteps} / ${task.totalSteps} sections`}
                  max={task.totalSteps}
                  current={task.completedSteps}
                />
              </DraggableTouch>
            )
          }
          return (<></>)
        })}
      </section>


    </DraggableBase>
  )
}

const mapStateToProps = state => ({
  assessments: state.workflows.assessments,
  alertTasks: state.system?.notifications?.filter(x => x.action && !x.actionRun),
  trainings: state.workflows.trainings,
  tasks: state.workflows.tasks,
  userID: state.user.id,
})

export default connect(mapStateToProps)(TasksToday)
