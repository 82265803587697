import React, { useState } from "react"
import Input from "aius-comp/Input"
import Badge from "aius-comp/Badge"
import Button from "aius-comp/Button"
import { connect } from 'react-redux'
import { Formik, Field } from "formik"
import * as Yup from "yup"
import { userActions, hotelActions, systemActions, workflowActions } from "../store/actions"
import axios from "axios"
import aiusLogo from '../images/icons/aius-logo.svg'
import c from './SignIn.module.scss'

const SignIn = props => {
  const { history, setUser, fetchHotels, fetchNotifications, fetchWorkflows } = props
  const [errorText, setErrorText] = useState("")
  
  const handleSignIn = ({ email, password }) => {
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/login`, { email, password })
      .then(res => {
        setUser(res.data)
        fetchHotels()
        fetchNotifications()
        fetchWorkflows()
        history.push("/")
      })
      .catch(e => {
        console.warn(e)
        setErrorText(e?.response?.data?.message ?? "Error occurred")
      })
  }

  return (
    <div className='d-flex align-items-center justify-content-center h-100'>
      <div className='d-flex flex-column flex-gap-1 w-100 p-5'>
        <div className="d-flex justify-content-center mb-5">
          <img className={c.aiusLogo} src={aiusLogo} alt=""/>
        </div>
        {errorText && (
          <Badge pill variant='warning' className='text-wrap'>
            {errorText}
          </Badge>
        )}
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={handleSignIn}
          validationSchema={Yup.object({
            email: Yup.string().email("Must be valid email").required("Email is required"),
            password: Yup.string().required("Password is required"),
          })}
        >
          {({ errors, handleSubmit }) => (
            <div onClick={() => setErrorText("")}>
              <Field name='email' error={errors.email} label='Email' as={Input} />
              <Field name='password' error={errors.password} type='password' label='Password' as={Input} />
              <div className='d-flex flex-column  flex-gap-1 justify-content-center'>
                <Button className='mt-4 mb-4' wide onClick={handleSubmit}>
                  Log in
                </Button>
                <span className={c.hint}>New user or forgot your password ?</span>
                <Button onClick={() => history.push("/forgot-password")} size='small' light>
                  Set password
                </Button>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setUser: (user) => dispatch(userActions.setUser(user)),
  fetchHotels: () => dispatch(hotelActions.fetchHotels()),
  fetchNotifications: () => dispatch(systemActions.fetchNotifications()),
  fetchWorkflows: () => dispatch(workflowActions.fetchWorkflows())
})

const mapStateToProps = state => ({
  hotelsFetched: state.hotels.fetched
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
