import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import Page from "../components/Page";
import ListItem from "../components/ListItem";
import IncidentBarChart from "../components/IncidentBarChart";
import IncidentBreakdown from "../components/IncidentBreakdown";
import { GoogleMap, LoadScript, Circle } from "@react-google-maps/api";
import closeIcon from "../images/icons/close-icon-white.svg";
import Tab from "../components/Tab";
import axios from "axios";
import HotelIncidentSummaryItem from "../components/HotelIncidentSummaryItem";
import Button from "aius-comp/Button";
import c from "./MultipleHotelsAnalytics.module.scss";
import ControlSelect from "../components/ControlSelect";
import { connect } from "react-redux";
import RiskItem from "../components/RiskItem";
import HotelRiskItemDelta from "../components/HotelRiskItemDelta"
import cn from "classnames";

/* 
  Analytics page for showing multiple locations

  TODO: 
  - Determine the bounding box of all incident coordinates and set center and zoom level accordingly
*/

const MultipleHotelsAnalytics = ({ notifications }) => {
    const map = useRef();
    const history = useHistory();
    const [severity, setSeverity] = useState();
    const [hotelStatistics, setHotelStatistics] = useState([]);
    const [dailyStatistics, setDailyStatistics] = useState();
    const [zoom, setZoom] = useState();
    const [days, setDays] = useState(7);
    const GMAP_KEY = process.env.REACT_APP_GMAPS_API_KEY;

    // Get incident statistics by location
    useEffect(() => {
        const data = { params: { severity, days } };
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/incidents/locations`,
                data
            )
            .then((res) => setHotelStatistics(res.data.locations))
            .catch((e) => console.error(e));
    }, [severity, days]);

    // Get incident statistics by day
    useEffect(() => {
        const data = { params: { days, reverse: true } };
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}/incidents/daily`, data)
            .then((res) => setDailyStatistics(res.data.statistics))
            .catch((e) => console.error(e));
    }, [days]);

    const center = {
        lat: 53.0,
        lng: 1.4,
    };

    const mapStyles = {
        width: "100%",
        height: "100%",
    };

    const mapOptions = {
        disableDefaultUI: true,
    };

    const sum = (array) =>
        Array.isArray(array) ? array.reduce((a, b) => a + b, 0) : 0;

    const radius = Math.min(32000, 15000 / (zoom / 5) ** 3.5);

    return (
        <Page>
            {/* Top Cover */}
            <div className={c.cover}>
                <div className={c.map}>
                    <LoadScript googleMapsApiKey={GMAP_KEY}>
                        <GoogleMap
                            ref={map}
                            mapContainerStyle={mapStyles}
                            center={center}
                            zoom={2}
                            options={mapOptions}
                            onZoomChanged={() =>
                                setZoom(map?.current?.state?.map?.zoom ?? 5)
                            }
                        >
                            {hotelStatistics?.map((hotel) => {
                                const coordinates = {
                                    lat: hotel.latitude,
                                    lng: hotel.longitude,
                                };
                                const { incidents } = hotel;
                                const severity =
                                    incidents.crisis > 0
                                        ? 4
                                        : incidents.severe > 0
                                        ? 3
                                        : incidents.major > 0
                                        ? 2
                                        : incidents.minor > 0
                                        ? 1
                                        : 0;
                                const colors = [
                                    "#FFFFFF",
                                    "#71ADBB",
                                    "#E0B259",
                                    "#d145ca",
                                    "#FF4A4A",
                                ];
                                const options = {
                                    strokeColor: "#FFFFFF",
                                    strokeWeight: 1.5,
                                    fillColor: colors[severity],
                                    strokeOpacity: severity > 0 ? 0.9 : 0,
                                    fillOpacity: severity > 0 ? 0.9 : 0,
                                };
                                return (
                                    <Circle
                                        key={hotel.id}
                                        center={coordinates}
                                        radius={radius}
                                        visible={true}
                                        options={options}
                                    />
                                );
                            })}
                        </GoogleMap>
                    </LoadScript>
                </div>
                <div className={c.closeContainer}>
                    <img
                        src={closeIcon}
                        className={c.close}
                        onClick={() => history.push("/")}
                        alt=""
                    />
                </div>
            </div>

            {/* Controls */}
            <ListItem>
                <div className="d-flex justify-content-between align-items-center w-100">
                    <h3>Incidents</h3>
                    <div className="d-flex flex-gap-2">
                        <ControlSelect
                            onChange={(e) => setDays(e.target.value)}
                            defaultValue="7"
                        >
                            <option value="7">Last 7 days</option>
                            <option value="14">Last 14 days</option>
                            <option value="30">Last 30 days</option>
                            <option value="90">Last 3 months</option>
                            <option value="365">Last 12 months</option>
                            <option value="">All</option>
                        </ControlSelect>
                    </div>
                </div>
            </ListItem>

            <div className={c.resChangeFlex}>
                {/* Graph | Stacked Bar Chart */}
                <Page.Section className={c.barchart}>
                    <div className="mt-5 mb-5">
                        <IncidentBarChart
                            minor={dailyStatistics?.minor}
                            major={dailyStatistics?.major}
                            severe={dailyStatistics?.severe}
                            crisis={dailyStatistics?.crisis}
                        />
                    </div>
                </Page.Section>

                {/* Graph | Incident Breakdown */}
                <Page.Section className={c.barchart}>
                    <div className="d-flex justify-content-center pt-5 pb-5">
                        <IncidentBreakdown
                            minor={sum(dailyStatistics?.minor)}
                            major={sum(dailyStatistics?.major)}
                            severe={sum(dailyStatistics?.severe)}
                            crisis={sum(dailyStatistics?.crisis)}
                        />
                    </div>
                </Page.Section>
            </div>

            <Page.Hr />

            {/* Tabs | Severity */}
            <Page.Section>
                <div className="d-flex justify-content-around pt-2 pb-2">
                    <Tab
                        onClick={() => setSeverity(undefined)}
                        active={severity === undefined}
                    >
                        All
                    </Tab>
                    <Tab
                        onClick={() => setSeverity("minor")}
                        active={severity === "minor"}
                    >
                        Minor
                    </Tab>
                    <Tab
                        onClick={() => setSeverity("major")}
                        active={severity === "major"}
                    >
                        Major
                    </Tab>
                    <Tab
                        onClick={() => setSeverity("severe")}
                        active={severity === "severe"}
                    >
                        Severe
                    </Tab>
                    <Tab
                        onClick={() => setSeverity("crisis")}
                        active={severity === "crisis"}
                    >
                        Crisis
                    </Tab>
                </div>
            </Page.Section>

            {/* List | Incidents by Hotel */}
            <Page.Section>
                <div
                    className={cn(
                        c.listingOnTab,
                        "d-flex flex-gap-3 pt-4 pb-4"
                    )}
                >
                    {hotelStatistics
                        ?.sort(
                            (a, b) =>
                                b.incidents.crisis +
                                b.incidents.severe +
                                b.incidents.major +
                                b.incidents.minor -
                                a.incidents.crisis -
                                a.incidents.severe -
                                a.incidents.major -
                                a.incidents.minor
                        )
                        .map(
                            (hotel) => (
                                <HotelIncidentSummaryItem
                                    className={c.eachItemOnTab}
                                    key={hotel.id}
                                    name={hotel.name}
                                    imageURL={hotel.coverImageURL}
                                    crisis={hotel.incidents.crisis ?? 0}
                                    severe={hotel.incidents.severe ?? 0}
                                    major={hotel.incidents.major ?? 0}
                                    minor={hotel.incidents.minor ?? 0}
                                    onClick={() =>
                                        history.push(
                                            `/hotels/${hotel.id}/analytics`,
                                            { hotel }
                                        )
                                    }
                                />
                            )
                        )}
                </div>
            </Page.Section>

            {/* Button | Reports */}
            <Page.Section>
                <div className="d-flex justify-content-end pb-4">
                    <Button onClick={() => history.push("/reports")}>
                        Reports
                    </Button>
                </div>
            </Page.Section>


            {/* List | Risk Changes */}

            <Page.Section>
                <h3>AIUS RISK MATURITY RATING&trade;</h3>
            </Page.Section>

            {/* List | Incidents by Hotel */}
            <Page.Section>
                <div
                    className={cn(
                        c.listingOnTab,
                        "d-flex flex-gap-3 pt-4 pb-4"
                    )}
                >
                    {hotelStatistics
                        ?.sort(
                            (a, b) =>
                                b.incidents.crisis +
                                b.incidents.severe +
                                b.incidents.major +
                                b.incidents.minor -
                                a.incidents.crisis -
                                a.incidents.severe -
                                a.incidents.major -
                                a.incidents.minor
                        )
                        .map((hotel) => {
                            if (hotel.risks.delta < 0 || hotel.risks.delta > 0 ) {
                                return (
                                    <HotelRiskItemDelta 
                                        className={c.eachItemOnTab} 
                                        id={hotel.id} key={hotel.id} 
                                        name={hotel.name} 
                                        city={hotel.city} 
                                        risks={hotel.risks}
                                        />
                                )
                            } else { return (null)}
                        })
                    }
                </div>
            </Page.Section>



            {/* List | Alerts & Notifications */}
            <div className={c.alerts}>
                <Page.Section>
                    <h3>Alerts & Notifications</h3>
                </Page.Section>
                {Array.isArray(notifications) && notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <RiskItem
                            key={notification.id}
                            type={notification.category}
                            severity={notification.severity}
                            title={notification.title}
                            subtitle={notification.message}
                            icon={notification.icon}
                        >
                            {notification?.action?.id && (
                                <div className="d-flex justify-content-end">
                                    {notification.actionRun === undefined ? (
                                        <Button
                                            size="small"
                                            variant="warning"
                                            onClick={() =>
                                                history.push(
                                                    `/alert-action/${notification.action.id}`,
                                                    {
                                                        notificationID:
                                                            notification.id,
                                                    }
                                                )
                                            }
                                        >
                                            Take action
                                        </Button>
                                    ) : (
                                        <Button
                                            disabled
                                            size="small"
                                            variant="grey"
                                        >
                                            Action taken
                                        </Button>
                                    )}
                                </div>
                            )}
                        </RiskItem>
                    ))
                ) : (
                    <div className="d-flex justify-content-center pt-4 pb-4">
                        <span>No alerts</span>
                    </div>
                )}
            </div>
        </Page>
    );
};

const mapStateToProps = (state) => ({
    notifications: state.system.notifications.sort(sortBySeverity),
});

const sortBySeverity = (a, b) => {
    const severities = { SEVERE: 0, MAJOR: 1, MINOR: 2 };
    if (severities[a.severity] > severities[b.severity]) {
        return 1;
    } else if (severities[a.severity] < severities[b.severity]) {
        return -1;
    } else {
        return 0;
    }
};

export default connect(mapStateToProps)(MultipleHotelsAnalytics);
