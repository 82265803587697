import types from "../types/reports"

export const reports = (state = { list: [], fetched: false }, { type, payload }) => {
  switch (type) {
    case types.fetchReports:
      return {
        ...state,
        list: payload,
        fetched: true,
      }
    default:
      return state
  }
}

export default reports 