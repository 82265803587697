import axios from "axios"
import types from "../types/system"
import { types as userTypes } from "../types/user"
import { types as workflowTypes } from "../types/workflows"
import { types as hotelTypes } from "../types/hotels"

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

export const flashMessage = payload => {
  return dispatch => {
    dispatch({ type: types.flashMessage, payload })
    setTimeout(() => {
      dispatch({ type: types.popMessage })
    }, 2300)
  }
}

export const showNotifications = () => {
  return { type: types.showNotifications }
}

export const hideNotifications = () => {
  return { type: types.hideNotifications }
}

export const removeNotification = (payload, snooze = false) => {
  if (snooze) {
    // Send snooze request
    axios.patch(`${process.env.REACT_APP_BACKEND_URL}/notifications/${payload}/snooze`).catch(e => {
      if (e.response.status === 501) {
        console.warn('[SystemActions] Snooze API is work in progress')
      }
    })
  } else {
    // Send acknowledge request
    axios.patch(`${process.env.REACT_APP_BACKEND_URL}/notifications/${payload}/acknowledge`).catch(e => {
      if (e.response.status === 501) {
        console.warn("[SystemActions] Snooze API is work in progress")
      } 
    })
  }
  // And asynchronously remove from store
  return { type: types.removeNotification, payload: payload }
}

export const fetchNotifications = () => {

  const showPopup = (dispatch) => {
    dispatch({ type: userTypes.removeUser })
    confirmAlert({
      title: 'You have been logged out',
      message: 'Please login again.',
      buttons: [
        {
          label: 'Login',
          onClick: () => {
            dispatch({ type: userTypes.removeUser })
            dispatch({ type: workflowTypes.removeWorkflows })
            dispatch({ type: hotelTypes.removeHotels })
          }
        }
      ]
    });
  }

  return dispatch => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/notifications`)
      .then(res => {
        if( res.data.status === false ){
          showPopup(dispatch);
        } else {
          dispatch({ type: types.setNotifications, payload: res.data.notifications })
        }
      })
      .catch(e => {
        showPopup(dispatch);
      })
  }
}

export const fetchMsgCount = () => {
  return dispatch => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/profile-message-count`)
      .then(res => {
          dispatch({ type: types.setMsgCount, payload: res.data.count })
      })
      .catch(e => {
        
      })
  }
}

export const decreaseMsgCountByOne = () => {
  return dispatch => {
    dispatch({ type: types.decreaseMsgCountByOne })
  }
}