import Page, { useMenu } from "../components/Page";
import { useHistory, useParams } from "react-router";
import Button from "aius-comp/Button"
import WorkflowRunAssignment from "../components/WorkflowRunAssignment";

const WRAssignments = (props) => {
    const { id } = useParams();
    const history = useHistory();
    const [openMenu, controls] = useMenu();

    return (
        <>
            <Page {...controls}>
                <Page.Top onMenuOpen={openMenu}>
                    Assign Task
                </Page.Top>
                <div className="d-flex flex-column flex-gap-2 mb-5 mt-5">
                    <WorkflowRunAssignment workflow_run_id={id} assignd_users={null} />
                </div>
                <Page.Section>
                    <div className="d-flex justify-content-between align-items-end mt-2">
                        <div></div>
                        <Button
                            size="large"
                            onClick={() => {
                                history.push(props.location?.state?.next)
                            }}
                        >
                            Next
                        </Button>
                    </div>
                </Page.Section>
            </Page>
        </>
    );
};

export default WRAssignments;
