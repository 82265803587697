import React from "react"
import cn from 'classnames'
import c from "./HotelRiskItemDelta.module.scss"
import RiskMaturityChart from '../components/RiskMaturityChart'


const HotelRiskItemDelta = props => {
  const {
    id,
    name,
    city,
    risks,
    className,
    ...rest
  } = props
  
  if (risks.delta > 0){
    return (
      <div className={cn(c.item, className)} {...rest}>    
        <div className={c.imageContainer}>
          <RiskMaturityChart value={risks.risk_rating}/>
          <div className={c.label}>{risks.risk_rating}</div>
        </div>
        
        <div className={c.content}>
          <div className={c.name}>{name}</div>
          <div className={c.total}>&uarr; {risks.delta}</div>
          <div className={c.name}>{risks.delta_date}</div>
        </div>
      </div>
    )
  } else if (risks.delta < 0){
    return (
      <div className={cn(c.item, className)} {...rest}>    
        <div className={c.imageContainer}>
          <RiskMaturityChart value={risks.risk_rating}/>
          <div className={c.label}>{risks.risk_rating}</div>
        </div>
        
        <div className={c.content}>
          <div className={c.name}>{name}</div>
          <div className={c.total}>&darr; {risks.delta}</div>
          <div className={c.name}>{risks.delta_date}</div>
          <div className={c.name}>{risks.delta_description}</div>
        </div>
      </div>
    )    
  } 
}

export default HotelRiskItemDelta