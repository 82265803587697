import { useState, useEffect } from "react";
import axios from "axios";
import IconItem from "./IconItem";
import Page from "./Page";
import c from './TrainingStateList.module.scss'
import { FcStart, FcApproval, FcCancel, FcHighPriority , FcAdvance, FcAlarmClock, FcLowPriority } from "react-icons/fc";
import ProgressBar from "./ProgressBar";

const TrainingStateList = ({id}) => {
    const [items,setItems] = useState([]);
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}/training/full`
            )
            .then((result) => {
                setItems(result.data)
            });
    }, [id]); 
    return (
        <Page.Section>
            {items.map(item =>(
                <div key={item.id} className={c.item}>
                    <IconItem icon={item.user.profileImageURL} text={item.user.firstName+' '+item.user.lastName} /> 
                    <div>
                        {item.state === 'passed' && (<><FcApproval /> </>)}
                        {item.state === 'failed' && (<><FcHighPriority /> </>)}
                        {item.state === 'incomplete' && (<><FcAdvance /> </>)}
                        {item.state === 'not_started' && (<><FcStart /> </>)}
                        {item.state === 'INPROGRESS' && (<><FcAlarmClock /> </>)}
                        {item.state === 'CLOSED' && (<><FcLowPriority /> </>)}
                        {item.state === 'CANCELLED' && (<><FcCancel /> </>)}
                        | Score : {item.score}
                        &nbsp;| Passed : {item.passed_steps} / {item.total_steps}
                        <ProgressBar current={item.passed_steps} max={item.total_steps} />
                    </div>
                </div>
            ))}
        </Page.Section>
    );
}

export default TrainingStateList;