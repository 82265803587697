import React from 'react'
import IncidentPieChart from './IncidentPieChart'
import cn from 'classnames'
import c from './IncidentBreakdown.module.scss'

/*
  Used in conjuction with IncidentPieChart to display incident statistics
*/

const IncidentBreakdown = props => {
  const { minor, major, severe, crisis, dashboard = false } = props

  const textClasses = dashboard ? c.dashboardText : c.normalText

  return (
    <div className={c.breakdown}>
      <IncidentPieChart minor={minor} major={major} severe={severe} crisis={crisis} />
      <div className={c.statistics}>
        <div className={c.row}>
          <div className={cn(c.circle, c.minor)}></div>
          <span className={textClasses}>{`${minor} minor incidents`}</span>
        </div>
        <div className={c.row}>
          <div className={cn(c.circle, c.major)}></div>
          <span className={textClasses}>{`${major} major incidents`}</span>
        </div>
        <div className={c.row}>
          <div className={cn(c.circle, c.severe)}></div>
          <span className={textClasses}>{`${severe} severe incidents`}</span>
        </div>
        <div className={c.row}>
          <div className={cn(c.circle, c.crisis)}></div>
          <span className={textClasses}>{`${crisis} critical incidents`}</span>
        </div>
      </div>
    </div>
  )
}

export default IncidentBreakdown
