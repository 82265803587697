import React, { useState, useEffect } from 'react'
import ArrowIcon from '../images/icons/arrow-right-small.svg'
import cn from 'classnames'
import c from './Expandable.module.scss'

/*
  Component expandable on click.
*/

export const Expandable = ({ text, children, beforeOpen, isExpanded = false }) => {
  const [open, setOpen] = useState(false)
  const iconClasses = cn(c.icon, { [c.icon_open]: open })
  const expandableClasses = cn(c.expandable, { [c.expandable_open]: open })

  useEffect(() => {
    setOpen(isExpanded)
  }, [isExpanded])

  return (
    <div className={c.container}>
      <div className={c.item} onClick={() => setOpen(!open)}>
        <span className={c.text}>{text}</span>
        <img className={iconClasses} src={ArrowIcon} alt=''/>
      </div>
      <div className={expandableClasses}>{children}</div>
    </div>
  )
}

export default Expandable