import React from 'react'
import { connect } from 'react-redux'

/*
  Use permissions to dynamically render components.
  Any permissions ending with ':view' should be used to determine rendering.
*/

const Cannot = ({ perform, permissions, children }) => {
  return (
    <>
      { !permissions.includes(perform) && <>{children}</>}
    </>
  )
}

const mapStateToProps = state => ({
  permissions: state.user.permissions
})

export default connect(mapStateToProps)(Cannot)
