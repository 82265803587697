import types from "../types/user"

export const setWelcomeVisited = () => {
  return { type: types.welcomePageVisited }
}

export const setUser = payload => {
  return { type: types.setUser, payload }
}

export const setToken = payload => {
  return { type: types.setToken, payload }
}

export const removeUser = () => {
  return { type: types.removeUser }
}

/*
export default {
  setWelcomeVisited,
  setToken,
  setUser,
  removeUser,
}
*/