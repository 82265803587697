import React, { useState } from 'react'
import Badge from 'aius-comp/Badge'
import Button from 'aius-comp/Button'
import { Field, Formik } from 'formik'
import Page from '../components/Page'
import * as Yup from "yup"
import { useHistory } from 'react-router'
import Input from 'aius-comp/Input'
import { systemActions } from '../store/actions'
import axios from "axios"
import { connect } from 'react-redux'

/*  
  Page used to send an email with further instructions for password reset
*/

const ForgotPassword = props => {
  const history = useHistory()
  const [errorText, setErrorText] = useState("")

  const submitResetRequest = (values) => {
    const data = {
      ...values
    }
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/auth/forgot-password`, data)
      .then(() => {
        props.flashMessage("Verification email sent 📧")
        history.push("/signin")
      })
      .catch(e => {
        console.error(e)
        setErrorText(e.response?.data?.message ?? "Error occurred")
      })
  }

  return (
    <Page>
      <div className='d-flex align-items-center justify-content-center h-100'>
        <div className='d-flex flex-column flex-gap-1 w-100 p-5'>
          <h1>Change my password 🔑</h1>
          <p>You'll receive a confirmation letter</p>
          {errorText && (
            <Badge pill variant='warning' className='text-wrap'>
              {errorText}
            </Badge>
          )}
          <Formik
            initialValues={{ email: "" }}
            onSubmit={submitResetRequest}
            validationSchema={Yup.object({
              email: Yup.string().email("Email must be valid").required("Email is required"),
            })}
          >
            {({ errors, handleSubmit }) => (
              <div>
                <Field name='email' error={errors.email} label='Email' as={Input} />

                <div className='d-flex flex-column flex-gap-1 justify-content-center'>
                  <Button className='mt-4' onClick={handleSubmit}>
                    Submit
                  </Button>
                  <Button size='small' light onClick={() => history.push("/signin")}>
                    Back
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  flashMessage: message => dispatch(systemActions.flashMessage(message)),
})

export default connect(null, mapDispatchToProps)(ForgotPassword)
