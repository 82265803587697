import React from 'react'
import IconItem from './IconItem'
import snoozeIcon from '../images/icons/snooze.svg'
import cn from 'classnames'
import c from './NotificationItem.module.scss'
import Button from "aius-comp/Button"
import { useHistory } from "react-router"




const NotificationItem = ({ 
  prefix, 
  title,
  subtitle = '',
  severity, 
  triggeredBy, 
  onSnooze,
  actionType,
  actionId,
  profileMessageId,
  onAcknowledge
}) => {
  
  const severities = { 
    'CRISIS': c.crisis,
    'SEVERE': c.severe,
    'MAJOR': c.major,
    'MINOR': c.minor,
    'NONE': c.none,
  }

  const history = useHistory()

  const barClasses = cn(c.bar, severities[severity])
  
  const profileImageURL = triggeredBy?.profileImageURL
  const firstName = triggeredBy?.firstName
  const lastName = triggeredBy?.lastName

  /*const redirectToAction = (thing) =>{ 
    //let path = `/assessments`; 
    ;
  }*/

  const handleClick = () => {
    if(profileMessageId){
      onAcknowledge()
      history.push(`/profile-message/${profileMessageId}`);
    } else {
      return false;
    }
  }

  function getActionUrl(type){
    switch(type) {
      case "TRAINING":
        return 'trainings';
      case "INCIDENT":
        return 'incidents';
      case "ASSESSMENT":
        return 'assessments';
      case "TASK":
        return 'tasks';
      default :
        return '';  
    }

  }

  

  return (
    <div className={c.item} onClick={handleClick}>
      <div className={barClasses}></div>
      <div className={c.content}>
        { prefix && <div className={c.prefix}>{prefix}</div> }
        <div>{title}</div>
        <div className={c.subtitle}>{subtitle}</div>
        { triggeredBy && <IconItem icon={profileImageURL} text={`${firstName} ${lastName}`} /> }
        { actionId ? <Button onClick={() => {history.push( '/' + getActionUrl(actionType) + '/' + actionId)}} variant='info' size='small'>Start</Button> : null }
      </div>
      { onSnooze && <img src={snoozeIcon} className={c.snoozeIcon} onClick={onSnooze} alt=''/> }
      
    </div>
  )
}



export default NotificationItem