import Page, { useMenu } from "../components/Page"
import Popup from "../components/ui/Popup"
import PreliminaryForm from "../components/assessments/PreliminaryForm"
import AssessmentHead from "../components/assessments/AssessmentHead"
import AssessmentSteps from "../components/assessments/AssessmentSteps"

const CreateAssessments = (props) => {
    const [openMenu, controls] = useMenu()
    return (
        <Page {...controls}>
            <Page.Top onMenuOpen={openMenu}>Create Assessments</Page.Top>
            <Page.Section>
                <Popup defaultState='open'>
                    <PreliminaryForm />
                </Popup>
                <AssessmentHead />
                <AssessmentSteps />
            </Page.Section>
        </Page>
    );
};

export default CreateAssessments;
