import React from 'react'
import cn from 'classnames'
import c from './Tab.module.scss'

/*
  Tab component with highlighting
*/

const Tab = ({ active = false, children, className, ...rest }) => {
  const tabClasses = cn(c.tab, { [c.active]: active }, className)
  
  return (
    <div className={tabClasses} {...rest} >
      {children}
      <div className={c.bar}></div>
    </div>
  )
}

export default Tab
