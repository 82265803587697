import React, { useState, useEffect } from "react"
import axios from "axios"
import ErrorPage from "aius-comp/ErrorPage"
import Loading from "aius-comp/Loading"
import AssessmentStepItem from "../components/AssessmentStepItem"
import { useHistory, useParams } from 'react-router'
import Page, { useMenu } from '../components/Page'
import ListItem from "../components/ListItem"
import IconItem from "../components/IconItem"
import CalendarIcon from "../images/icons/calendar.svg"
import Can from "../components/Can"
import Cannot from "../components/Cannot"
import c from './AssessmentProgress.module.scss'
import AssessmentAssignments from "../components/assessments/AssessmentAssignments"
import Button from "aius-comp/Button"

/*  
  Show progress of a specific assessment.

  TODO: 
  - Move score calculations to backend (Logic exists in assessments controller)
*/

const AssessmentProgress = props => {
  const onBackPath = props.location?.state?.onBackPath ?? '/'
  const [openMenu, controls] = useMenu()
  const [assessment, setAssessment] = useState()
  const [loading, setLoading] = useState(true)
  const history = useHistory()
  const { id } = useParams()

  useEffect(() => {
    const run = props.location?.state?.run

    if (run) {
      setAssessment(run)
      setLoading(false)
    } else {
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}/full`)
        .then(res => setAssessment(res.data?.workflowRun ?? undefined))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // If workflow loading
  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  // If not found
  if (!loading && !assessment) {
    console.error("Couldn't retrieve workflow")
    return <ErrorPage title='No workflow' description='Workfload could not be loaded' />
  }

  const location = assessment.locations[0]

  const saveHandler = () => {
    setLoading(true)
    axios.patch(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}`,{state:'CLOSED'})
        .then(res => history.push(onBackPath))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
  }

  return (
    <Page {...controls}>
      <Page.Top back onBack={() => history.push(onBackPath)} onMenuOpen={openMenu}>
        {assessment.workflow?.name}
      </Page.Top>

      {/* Metadata */}
      <Page.Section>
        <div className={c.metadata}>
          <Can perform='assessment-progress-metadata:view'>
            <ListItem>
              <IconItem icon={location.coverImageURL} text={location.name} />
            </ListItem>
            <span className={c.title}>Assigned to</span>
            <ListItem>
              <IconItem
                icon={assessment.createdBy.profileImageURL}
                text={`${assessment.createdBy.firstName} ${assessment.createdBy.lastName}`}
              />
            </ListItem>
          </Can>
          <span className={c.title}>Started</span>
          <ListItem>
            <IconItem icon={CalendarIcon} text={new Date(assessment.createdAt).toLocaleDateString()} />
          </ListItem>
          <span className={c.title}>Updated</span>
          <ListItem>
            <IconItem icon={CalendarIcon} text={new Date(assessment.updatedAt).toLocaleDateString()} />
          </ListItem>
        </div>
      </Page.Section>

      <Can perform='assessments-assignment:view'>
        <AssessmentAssignments workflowId={assessment.workflow.id}/>
      </Can>

      <Cannot perform='assessments-assignment:view'>
        {/* Steps */}
        <div className={c.steps}>
          {/* For each workflow step, find corresponding response step */}
          {assessment.workflow?.steps?.map((workflowStep, stepIndex) => {
            // Find run step
            const step = assessment.steps.find(runStep => runStep.workflowStepId === workflowStep.id) || undefined
            if (!step) {
              // If run step not found - hasn't been started

              return (
                <AssessmentStepItem
                  key={workflowStep.id}
                  name={workflowStep.name}
                  date={""}
                  score={""}
                  status={"pending"}
                  onClick={() =>
                    history.push(`/assessments/${assessment.workflow.id}/run`, { run: assessment, stepID: workflowStep.id, stepIndex:stepIndex })
                  }
                />
              )
            } else {
              // If run step found - step was started or is completedassessment.createdBy.firstName

              // Calculate score
              const { responses, createdAt } = step
              const { name, maxScore, passScore } = workflowStep
              const currentScore = responses.reduce((sum, response) => sum + (response.option?.weight ?? 0), 0)

              // Calculate step state [Temp. solution]
              const parentStepElements = workflowStep.elements?.reduce((sum, el) => (el.type === "SELECT" ? sum + 1 : sum), 0)
              const responseStepElements = responses?.reduce((sum, res) => (res.element.type === "SELECT" ? sum + 1 : sum), 0)

              const isStepCompleted = parentStepElements === responseStepElements
              const stepState = isStepCompleted ? (currentScore >= passScore ? "pass" : "fail") : "incomplete"

              const date = new Date(createdAt).toLocaleDateString("en-gb", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })

              return (
                <AssessmentStepItem
                  key={workflowStep.id}
                  name={name}
                  date={date}
                  score={`${currentScore} / ${maxScore}`}
                  status={stepState}
                  onClick={() =>
                    history.push(`/assessments/${assessment.workflow.id}/run`, { run: assessment, stepID: workflowStep.id, stepIndex:stepIndex })
                  }
                />
              )
            }
          })}
        </div>

        <div className="d-flex justify-content-center align-items-center pb-5">
            <Button size="normal" onClick={saveHandler}>
                Finish
            </Button>
        </div>
      </Cannot>


    </Page>
  )
}

export default AssessmentProgress
