import { useHistory } from 'react-router';
import c from'./NewAssessmentStep.module.scss'

const NewAssessmentStep = (props) => {
    const history = useHistory()
    return (
        <div className={c.item}>
            <button className={c.createNewButton} onClick={()=>{ history.push(props.goto)}}>{props.content}</button>
        </div>
    );
};

export default NewAssessmentStep;
