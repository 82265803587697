import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Page, { useMenu } from "../components/Page";
import ReportListItem from "../components/ReportListItem";
import axios from "axios";
import Button from "aius-comp/Button";
import { RiMailSendLine, RiInboxFill } from "react-icons/ri";
import { SiMinutemailer } from "react-icons/si";

const Mail = ({ hotels }) => {
    const [openMenu, controls] = useMenu();
    const [messages, setMessages] = useState([]);
    const history = useHistory();
    const { pathname } = history.location;
    const box = pathname.split("/")[2];

    // Get incidents
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_BACKEND_URL}${pathname}`)
            .then((res) => {
                setMessages(res.data.data);
            });
    }, [pathname]);

    return (
        <Page {...controls}>
            <Page.Top
                back={true}
                onBack={() => {
                    history.goBack();
                }}
                onMenuOpen={openMenu}
            >
                <div style={{ textTransform: "capitalize" }}>{box}</div>

                <div className="d-flex justify-content-between align-items-center mt-2">
                    <Button
                        size="small"
                        variant="success"
                        onClick={() => history.push("/profile-message")}
                    >
                        {" "}
                        <RiMailSendLine /> Send Messages
                    </Button>
                    {box === "inbox" ? (
                        <Button
                            size="small"
                            variant="success"
                            onClick={() => history.push("/mail/outbox")}
                        >
                            {" "}
                            <SiMinutemailer /> Outbox
                        </Button>
                    ) : (
                        <Button
                            size="small"
                            variant="success"
                            onClick={() => history.push("/mail/inbox")}
                        >
                            {" "}
                            <RiInboxFill /> Inbox
                        </Button>
                    )}
                </div>
            </Page.Top>

            {/* List | Reports */}
            <div>
                {messages.map((message) => (
                    <ReportListItem
                        key={message.id}
                        title={message.title}
                        date={message.created_at}
                        severity={message.severity.toLowerCase()}
                        is_seen={message.is_seen}
                        iconText={`${message.from.firstName} ${message.from.lastName}`}
                        iconURL={message.from.profileImageURL}
                        onClick={() =>
                            history.push({pathname:`/profile-message/${message.id}`, state:{ box : box }})
                        }
                    />
                ))}
            </div>
        </Page>
    );
};


const mapStateToProps = (state) => ({
    hotels: state.hotels.list,
});

export default connect(mapStateToProps)(Mail);
