import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";
import Button from "aius-comp/Button";
import CloseHeader from "../components/CloseHeader";
import ReportStep from "../components/ReportStep";
import { Formik } from "formik";
import axios from "axios";
import ErrorPage from "aius-comp/ErrorPage";
import Loading from "aius-comp/Loading";
import Page from "../components/Page";
import { readFile } from "aius-fn";
import c from "./ReportRun.module.scss";
import WorkflowExitPopup from "../components/WorkflowExitPopup";
import { FormicTypeInput } from "aius-comp/Input";
import { Select } from "aius-comp/Select";
import { connect } from "react-redux";
import { systemActions } from "../store/actions";

const ReportProgress = (props) => {
    const { id } = useParams();
    const { history } = props;
    const [loading, setLoading] = useState(true);
    const [submitting, setSubmitting] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [initialInfoEntered, setInitialInfoEntered] = useState(false);
    const [run, setRun] = useState(undefined);
    const [index, setIndex] = useState(undefined);
    const [formicTypeSelectValue, setformicTypeSelectValue] =
        useState(undefined);
    useEffect(() => {
        const run = props.location?.state?.run;

        if (run) {
            setRun(run);
            setLoading(false);
        } else {
            axios
                .get(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}/full`)
                .then((res) => setRun(res.data?.workflowRun ?? undefined))
                .catch((err) => console.error(err))
                .finally(() => setLoading(false));
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // If workflow loading
    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center h-100">
                <Loading size="large" />
            </div>
        );
    }

    // If not found
    if (!loading && !run) {
        console.error("Couldn't retrieve workflow");
        return (
            <ErrorPage
                title="No workflow"
                description="Workfload could not be loaded"
            />
        );
    }

    const { workflow } = run;
    const { steps, name } = workflow;

    // If no steps
    if (!steps || steps.length === 0) {
        return (
            <ErrorPage
                title="No steps"
                description="Workflow doesn't have any associated steps"
            />
        );
    }

    // Sort steps by order
    steps.sort((step1, step2) => step1.sortOrder - step2.sortOrder);

    let currentStep;
    if (index !== undefined) {
        currentStep = steps[index];
    } else {
        setIndex(0);
        currentStep = steps[0];
    }

    // If no steps
    if (!currentStep) {
        return (
            <ErrorPage
                title="Step not found"
                description="Workflow doesn't have step with provided ID"
            />
        );
    }

    // Get existing values for the step
    // const existingStep = run.steps.find(step => step.workflowStepId == stepID)
    // const responses = existingStep?.responses ?? []

    const maxSteps = steps.length;
    const isLastStep = index === maxSteps - 1;

    const submitHandler =
        (state = "CLOSED") =>
        async (values) => {
            setSubmitting(true);
            try {
                const data = {
                    state: state,
                    elements: [],
                    files: [],
                    title: values._title,
                    severity: values._severity,
                    occurredAt: values._occurredAt,
                };

                const runId = run.id;

                delete values._severity;
                delete values._occurredAt;
                delete values._title;

                for (const key in values) {
                    let element = values[key];
                    if (Array.isArray(values[key])) {
                        try {
                            //TODO we only look at the first file - which is fine atm because they can only select one
                            const parsedFile = await readFile(element[0].value);
                            data.files.push({
                                elementId: element[0].elementId,
                                stepId: element[0].stepId,
                                fileName: parsedFile.fileName,
                                fileType: parsedFile.fileType,
                                fileSize: parsedFile.fileSize,
                                fileBase64: parsedFile.fileBase64,
                            });
                        } catch (err) {
                            console.error(
                                "[ReportRun] Failed to parse attachment: ",
                                err
                            );
                        }
                    } else {
                        data.elements.push({
                            stepId: element.stepId,
                            elementId: element.elementId,
                            value: element.value,
                        });
                    }
                }
                // Submit workflow
                axios.patch(
                    `${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${runId}`,
                    data
                );
                setShowModal(false);
                setSubmitting(false);
                props.flashMessage("Report submitted ✅");
                history.push("/reports");
            } catch (e) {
                setSubmitting(false);
                props.flashMessage("Error occurred...");
                console.error(e);
            }
        };

    // Step pagination
    const changeStep = (change) => {
        return () => {
            const nextIndex = Math.min(
                maxSteps - 1,
                Math.max(0, index + change)
            );
            if (index !== nextIndex) {
                setIndex(nextIndex);
            }
        };
    };

    const next = changeStep(1);
    const prev = changeStep(-1);

    // Cancel assessment
    const cancelAssessment = () => {
        const id = run.id;
        const data = {
            state: "CANCELLED",
        };
        axios
            .patch(
                `${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}`,
                data
            )
            .then(() => {
                setShowModal(false);
                history.push("/");
            })
            .catch((err) => {
                console.error(err);
                setSubmitting(false);
            });
    };

    const defaultSeveritis = [
        { value: "MINOR", label: "Minor" },
        { value: "MAJOR", label: "Major" },
        { value: "SEVERE", label: "Severe" },
        { value: "CRISIS", label: "Crisis" },
    ];

    //setformicTypeSelectValue(defaultSeveritis.filter(option => option.value === run.severity));
    return (
        <Page>
            <Page.Section>
                {/* Top */}
                <CloseHeader title={name} onClose={() => setShowModal(true)} />
                <ProgressBar
                    current={index}
                    max={steps.length - 1}
                    className="mb-5"
                />

                {/* Steps */}
                <Formik
                    initialValues={{
                        _title: run.title,
                        _severity: run.severity,
                        _occurredAt: run.occurredAt
                            ? run.occurredAt.toString().substring(0, 16)
                            : undefined,
                    }}
                    onSubmit={submitHandler("CLOSED")}
                    validateOnMount={true}
                >
                    {({
                        handleSubmit,
                        setFieldValue,
                        isValid,
                        values,
                        handleChange,
                    }) => (
                        <>
                            {/* Steps */}
                            <div className={c.steps}>
                                <ReportStep
                                    step={currentStep}
                                    responses={currentStep.responses}
                                />
                            </div>

                            {/* Navigation */}
                            <div className={c.bottom}>
                                <Button onClick={prev} text>
                                    Back
                                </Button>
                                {isLastStep ? (
                                    <Button
                                        onClick={handleSubmit}
                                        disabled={submitting}
                                    >
                                        Submit
                                    </Button>
                                ) : (
                                    <Button onClick={next}>Next</Button>
                                )}
                            </div>

                            {/* Pop-up */}
                            {showModal && (
                                <WorkflowExitPopup
                                    onClose={() => setShowModal(false)}
                                >
                                    <div className="d-flex flex-column justify-content-end flex-gap-2 align-items-center">
                                        <Button
                                            onClick={() => setShowModal(false)}
                                            variant="grey"
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={() => cancelAssessment()}
                                            variant="warning"
                                        >
                                            Discard
                                        </Button>
                                        <Button
                                            disabled={submitting}
                                            onClick={() =>
                                                submitHandler("INPROGRESS")(
                                                    values
                                                )
                                            }
                                        >
                                            Save
                                        </Button>
                                    </div>
                                </WorkflowExitPopup>
                            )}
                            {/* Initial Information Pop-up */}
                            {!initialInfoEntered && (
                                <div className={c.initialPopupOverlay}>
                                    <div className={c.initialPopup}>
                                        <h1 className="mb-5">
                                            Enter preliminary information
                                        </h1>
                                        <div className="d-flex flex-column flex-gap-2 mb-5">
                                            <FormicTypeInput
                                                name="_title"
                                                label="Incident title"
                                            />

                                            <Select
                                                name="_severity"
                                                label="Incident severity"
                                                isSearchable={false}
                                                value={
                                                    formicTypeSelectValue ??
                                                    defaultSeveritis.filter(
                                                        (option) =>
                                                            option.value ===
                                                            run.severity
                                                    )
                                                }
                                                options={defaultSeveritis}
                                                onChange={(e) => {
                                                    setformicTypeSelectValue(e);
                                                    return setFieldValue(
                                                        "_severity",
                                                        e.value
                                                    );
                                                }}
                                            />
                                            <FormicTypeInput
                                                name="_occurredAt"
                                                label="When incident occurred"
                                                type="datetime-local"
                                            />
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                            <Button
                                                size="small"
                                                variant="warning"
                                                onClick={() =>
                                                    cancelAssessment()
                                                }
                                            >
                                                Discard
                                            </Button>
                                            <Button
                                                size="normal"
                                                onClick={() =>
                                                    setInitialInfoEntered(true)
                                                }
                                            >
                                                Continue
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </Formik>
            </Page.Section>
        </Page>
    );
};

const mapDispatchToProps = (dispatch) => ({
    flashMessage: (message) => dispatch(systemActions.flashMessage(message)),
});

export default connect(null, mapDispatchToProps)(ReportProgress);
