import c from "./SearchResultList.module.scss";
import { useHistory } from "react-router-dom";
const SearchResultList = (props) => {
    const history = useHistory();
    return (
        <div className={c.autocomplete}>
            {props.dropdownVisible && (
                <div className={c.autocompleteDropdown}>
                    <ul className={c.autocompleteSearchResultsList}>
                        {Object.keys(props.hubResult).map((category) => {
                            if (category === "debug") {
                                return <></>;
                            }
                            return (
                                <li key={category} className={c.autocompleteSearchResultsCategory}>
                                    <span className={c.catSpan}>
                                        {category}
                                    </span>
                                    <ul className={c.autocompleteSearchResultsList}>
                                        {props.hubResult[category].map(
                                            (result) => {
                                                let link = "/";
                                                let suffix = "";
                                                let name = result.name;
                                                let date = "";
                                                if (category === "create") {
                                                    suffix = result.type + ": ";
                                                    if (result.type ==="Incident") {
                                                        link ="/report/" + result.id;
                                                    }

                                                    if (result.type === "Assessment") {
                                                        link ="/assessments/" + result.id + "/progress";
                                                    }
                                                } else if (category === "result") {
                                                    suffix = result?.workflow?.type ?? "" + ": ";
                                                    if (result?.workflow?.type === "Incident") {
                                                        link = "/reports/" + result.id;
                                                        name = result.name ?? result.workflow.name;
                                                        date = new Date( result.created_at ).toLocaleString("en-GB");
                                                    }

                                                    if (result?.workflow?.type === "Assessment") {
                                                        link = "/assessments/" + result.id + "/progress";
                                                        name = result.workflow.name;
                                                    }

                                                    if (result?.workflow?.type === "Training") {
                                                        link = "/trainings/" + result.id +"/progress";
                                                        name = result.workflow.name;
                                                    }
                                                } else {
                                                    // Document
                                                    link ="/documents/" +result.id;
                                                }
                                                console.log(category)
                                                return (
                                                    <li className={c.autocompleteSearchResult} key={result.id}>
                                                        <button className={c.rButton} 
                                                            onClick={() => {
                                                                history.push(link);
                                                            }}
                                                        >
                                                            <b>{suffix}</b> {name}{" "}<i className={c.idate}>{date}</i>
                                                        </button>
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default SearchResultList;
