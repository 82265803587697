import React from "react";

const AssessmentHead = props => {

    return (
        <React.Fragment>
            <h3>ASDasd asd asd</h3>
            <p>Descasda laksdijoiasd alisdlkasd laksdn</p>
        </React.Fragment>
    );
}

export default AssessmentHead;