import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Page, { useMenu } from '../components/Page'
import AssessmentListItem from "../components/AssessmentListItem"
import ControlSelect from "../components/ControlSelect"
import c from './Assessments.module.scss'
import axios from 'axios'
import { useHistory, useParams } from 'react-router'
import { systemActions } from '../store/actions'
import Button from "aius-comp/Button";
import Loading from "aius-comp/Loading"
import Cannot from '../components/Cannot'
/*
  Show assessments and progress of each hotel
*/

const Assessments = ({ flashMessage }) => {
  const history = useHistory()
  const [openMenu, controls] = useMenu()
  const [assessments, setAssessments] = useState([])
  const [allAssessments, setAllAssessments] = useState([])
  const [status, setStatus] = useState('')
  const [loading, setLoading] = useState(true)
  const { id } = useParams()

  useEffect(() => {
    setLoading(true)
    const data = {
      params: {
        categoryId:id
      }
    }
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/assessments`, data)
      .then(res => {
        setAssessments(res.data.assessments)
        setAllAssessments(res.data.assessments)
        setLoading(false)
      })
      .catch(e => {
        console.error(e)
        setLoading(false)
      })
  }, [id])

  const filterHandler = (status) => {
    if(status === ''){
      setAssessments(allAssessments);
    } else if(status === 'incomplete') {
      setAssessments(allAssessments.filter(a => a.state === 'INPROGRESS'))
    } else if(status === 'not started') {
      setAssessments(allAssessments.filter(a => a.started === false))
    } else if(status === 'failed') {
      setAssessments(allAssessments.filter(a => a.isPassed === false))
    } else if(status === 'passed') {
      setAssessments(allAssessments.filter(a => a.isPassed === true))
    }
  }

  // If workflow loading
  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  return (
    <Page {...controls}>
      <Page.Top onMenuOpen={openMenu}>Assessments</Page.Top>
      <div className={c.controls}>
        <ControlSelect onChange={e => setStatus(e.target.value)} defaultValue=''>
          <option value=''>All</option>
          <option value='passed'>Passed</option>
          <option value='incomplete'>Incomplete</option>
          <option value='failed'>Failed</option>
          <option value='not started'>Not Started</option>
        </ControlSelect>
        {false && (<Button size='small' light variant='grey' onClick={() => history.push("/create-assessments")}>
          New Assessments
        </Button>)}
      </div>
      <div>
        {assessments.map(assessment => (
          <AssessmentListItem
            key={`${assessment.id}-${assessment.name}`}
            name={assessment.name}
            status={assessment.state}
            hotelImage={assessment?.location?.coverImageURL}
            hotelName={assessment?.location?.name}
            currentProgress={assessment.completedSteps}
            maxProgress={assessment.totalSteps}
            currentScore={assessment.currentScore}
            maxScore={assessment.maxScore}

            completed={assessment.completed}
            totalHotelCount={assessment.totalHotelCount}
            failed={assessment.failed}
            passed={assessment.passed}
            onClick={() => {
              if (assessment.started) {
                history.push(`/assessments/${assessment.runID}/progress`, { onBackPath: "/assessments" })
              } else {
                flashMessage("Assessment hasn't been started")
                history.push(`/assessments/${assessment.id}/assignment`, { onBackPath: "/assessments" })
              }
            }}
          />
        ))}
      </div>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  flashMessage: (message) => dispatch(systemActions.flashMessage(message))
})

export default connect(null, mapDispatchToProps)(Assessments)
