import React from 'react'
import cn from 'classnames'
import c from './ControlSelect.module.scss'

const ControlSelect = ({ children, className, ...rest }) => {
  const selectClasses = cn(c.select, className)
  return (
    <select className={selectClasses} {...rest}>
      { children }
    </select>
  )
}

export default ControlSelect
