import React from "react"
import PropTypes from 'prop-types'
import IconItem from './IconItem'
import ProgressBar from './ProgressBar'
import cn from 'classnames'
import c from "./AssessmentListItem.module.scss"
import Can from './Can'
import Cannot from './Cannot'
import { FcStart, FcApproval, FcCancel, FcHighPriority , FcAdvance, FcAlarmClock, FcLowPriority } from "react-icons/fc";

const TrainingListItem = props => {
  const {
    name,
    status,
    hotelImage,
    hotelName,
    currentProgress,
    maxProgress,
    currentScore,
    maxScore,
    completed,
    totalAssigned,
    passed,
    stateCount,
    onClick
  } = props

  const state = {
    "failed": "warning",
    "passed": "healthy",
    "incomplete": "incomplete",
    "not started": "incomplete",
    "INPROGRESS": "incomplete",
    "CLOSED": "healthy",
    "CANCELLED": "warning"
  }

  const stateClasses = {
    incomplete: c.incomplete,
    healthy: c.healthy,
    warning: c.warning
  }

  const statusClass = cn(c.status, stateClasses[state[status]])

  return (
    <div className={c.item} onClick={onClick}>
      <div className={c.bar}></div>
      <div className={c.content}>
        <div className={c.top}>
          <div className={c.name}>{name}</div>
          <Cannot perform='hotels:aggregate'>
            <div className={cn(c.status, statusClass)}>{status}</div>
          </Cannot>
        </div>

        <Can perform='hotels:aggregate'>
            <div className={c.hotel}>
              Total Assigned : {totalAssigned}
            </div>

            <div>
              <div className={c.progression}>
                <div>{`Completed: ${stateCount.incomplete}/${totalAssigned}`}</div>
                <div style={{ width: "150px" }}>
                  <ProgressBar current={stateCount.incomplete} max={totalAssigned} />
                </div>
              </div>

              <div className={c.progression}>
                <div>{`Passed: ${stateCount.passed}/${stateCount.incomplete}`}</div>
                <div style={{ width: "150px" }}>
                  <ProgressBar current={stateCount.passed} max={stateCount.incomplete} />
                </div>
              </div>
            </div>
        </Can>

        <Cannot perform='hotels:aggregate'>
          <div className={c.hotel}>
            <IconItem icon={hotelImage} text={hotelName} />
          </div>

          <div>
            <div className={c.progression}>
              <div>{`Progress: ${currentProgress}/${maxProgress}`}</div>
              <div style={{ width: "150px" }}>
                <ProgressBar current={currentProgress} max={maxProgress} />
              </div>
            </div>
            <div className={c.progression}>
              <div>{`Score: ${currentScore}/${maxScore}`}</div>
              <div style={{ width: "150px" }}>
                <ProgressBar current={currentScore} max={maxScore} state={state[status]} />
              </div>
            </div>
          </div>
        </Cannot>
        
      </div>
    </div>
  )
}

TrainingListItem.propTypes = {
  status: PropTypes.oneOf(['passed', 'failed', 'incomplete', 'not started', 'INPROGRESS', 'CLOSED', 'CANCELLED'])
}

TrainingListItem.defaultProps = {
  currentProgress: 0,
  maxProgress: 1,
  currentScore: 0,
  maxScore: 1,
  totalAssigned:0,
  stateCount: {
    passed: 0,
    failed: 0,
    incomplete: 0,
    not_started: 0,
    INPROGRESS: 0,
    CLOSED: 0,
    CANCELLED: 0
  }
}

export default TrainingListItem
