import React from 'react'
import c from './SelectIcon.module.scss'

const SelectIcon = ({ label, url }) => {
  return (
    <div className={c.label}>
      <img src={url} className={c.icon} alt='Selection Icon'/>
      <div>{label}</div>
    </div>
  )
}

export default SelectIcon
