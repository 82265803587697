import React from 'react'
import BaseTaskItem from './BaseTaskItem'
import ProgressBar from './ProgressBar'

const ProgressTaskItem = ({
  title,
  subtitle,
  description,
  current = 0,
  max = 1,
  ...rest
}) => {
  const subtitleAndDescription = (
    <div className="d-flex justify-content-between align-items-center w-100">
      <div>{subtitle}</div>
      <div>{description}</div>
    </div>
  )
  return (
    <BaseTaskItem title={title} subtitle={subtitleAndDescription} {...rest}>
      <ProgressBar current={current} max={max} state='healthy' size='small' />
    </BaseTaskItem>
  )
}

export default ProgressTaskItem
