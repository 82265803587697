import TabHeader from "../../components/ui/tablayouts/TabHeader"
import Page, { useMenu } from "../../components/Page"
import MultipleHotelsAnalytics from "../MultipleHotelsAnalytics"
import TabDashboardSummary from "../../components/ui/tablayouts/TabDashboardSummary"
import c from './TabDashboard.module.scss'
import Can from "../../components/Can"
import HotelAnalytics from "../HotelAnalytics"
import TabTasksToday from "../../components/TabTasksToday"
import Button from "../../library/Button"
import { useHistory } from "react-router-dom"
import Cannot from "../../components/Cannot"

const TabDashboard = () => {
    const [openMenu, controls] = useMenu();
    const history = useHistory()
    return (
        <Page {...controls} dark active={true}>
            <TabHeader onMenuOpen={openMenu}/>
            <Page.Section className='d-flex justify-content-between flex-gap-1'>
                <Can perform='hotels:aggregate'>
                    <div className={c.bigsection}>
                        <MultipleHotelsAnalytics />
                    </div>
                    <div className={c.smallsection}>
                        <TabDashboardSummary />
                    </div>
                </Can>
                
                <Cannot perform='hotels:aggregate'>
                    <div className={c.bigsection}>
                        <Can perform='dashboard-hotel-analytics:view'>
                            <HotelAnalytics source='TabDashboard'/>
                        </Can>
                        <Page.Section>
                            <div className='d-flex flex-column flex-gap-1'>
                            <Can perform='dashboard-document-library:view'>
                                <Button wide onClick={() => history.push("/documents")} className='mt-3'>
                                    Document Library
                                </Button>
                            </Can>
                            <Can perform='dashboard-new-incident:view'>
                                <Button wide variant='warning' onClick={() => history.push("/actions")}>
                                    New Report
                                </Button>
                                <Button wide onClick={() => history.push("/training")}>
                                    Training
                                </Button>
                                <Button wide variant='warning' onClick={() => history.push("/new-tasks")}>
                                    New Task
                                </Button>
                            </Can>
                            
                            </div>
                        </Page.Section>
                    </div>

                    <div className={c.smallsection}>
                        <Can perform='dashboard-tasks:view'>
                            <TabTasksToday />
                        </Can>
                    </div>
                </Cannot>
            </Page.Section>
        </Page>
    );
};

export default TabDashboard;
