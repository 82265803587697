import React, { useEffect, useState } from "react"
import { useHistory, useParams} from "react-router-dom"
import { connect } from "react-redux"
import { systemActions, userActions } from "../store/actions" 
import capitalise from '../functions/capitalise'
import Page, { useMenu } from '../components/Page'
import ListItem from '../components/ListItem'
import Loading from "aius-comp/Loading"
import Button from 'aius-comp/Button'
import IconItem from "../components/IconItem"
import SeverityCircle from "../components/SeverityCircle"
import DateIcon from '../images/icons/calendar.svg'
import FileIcon from '../images/icons/file-icon.svg'
import axios from "axios"
import c from "./IncidentDetails.module.scss"
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import WorkflowRunAssignment from "../components/WorkflowRunAssignment"
import { BiCalendarExclamation } from "react-icons/bi";

const EachMassage = ({eachData}) => {
  const {user} = eachData;
  return (
    <div className={c.msgList}>
      <b>{`${user.firstName} ${user.lastName}`}:</b> {eachData.text}
      <div className={c.ago}>{eachData.ago}</div>
    </div>
  )
}

const TaskDetails = ({ flashMessage }) => {
  const history = useHistory()
  const [openMenu, controls] = useMenu()
  const [task, setTask] = useState()
  const [messages, setMessages] = useState([]);
  const reportID = useParams().id

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND_URL}/tasks/${reportID}`)
      .then(res => {
        setTask(res.data.task)
      })
      .catch(e => {
        flashMessage("Couldn't load Task ❌")
        console.error(e)
      })
      axios.get(`${process.env.REACT_APP_BACKEND_URL}/workflow-run-comments/${reportID}`)
        .then(res => {
          let messages = [];
          res.data.map((eachData) => {
            messages.push(<EachMassage eachData={eachData} />)

            return false
          })

          setMessages(messages);
        })
        .catch(e => {
          console.error(e)
        })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (!task) {
    return (
      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
        <Loading />
      </div>
    )
  }

  // Delete Task
  const DeleteTask = () => {
    confirmAlert({
      title: 'Confirm to submit',
      message: 'Are you sure to do this.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            axios.delete(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${reportID}`)
              .then(() => {
                history.push("/tasks")
              })
              .catch(err => {
                console.error(err)
              })

          }
        },
        {
          label: 'No',
          onClick: () => {}
        }
      ]
    });    
  }

  return (
    <Page {...controls} active={true}>
      <Page.Top back onBack={() => history.goBack()} onMenuOpen={openMenu}>
        {task.workflow.name}
      </Page.Top>

      {/* Report Title */}
      <ListItem className='mt-4 d-flex justify-content-between align-items-center'>
        <h3>{task.title}</h3>        
      </ListItem>

      {/* Occurred at Locations */}
      {task.locations.map(location => (
        <ListItem key={location.id}>
          <IconItem text={location.name} icon={location.coverImageURL} />
        </ListItem>
      ))}
      <ListItem>
        <IconItem text={new Date(task.occurredAt).toDateString()} icon={DateIcon} />
      </ListItem>

      {/* Severity */}
      <ListItem>
        <div className='ml-2 d-flex align-items-center'>
          <SeverityCircle severity={task.severity.toLowerCase()} />
          <span className='ml-4'>{capitalise(task.severity.toLowerCase())}</span>
        </div>
      </ListItem>

      {/* Reporter */}
      <Page.Section className='mt-4'>
        <span className={c.title}>Completed by</span>
      </Page.Section>
      <ListItem>
        <IconItem text={`${task.createdBy.firstName} ${task.createdBy.lastName}`} icon={task.createdBy.profileImageURL} />
      </ListItem>
      <ListItem>
        <IconItem text={new Date(task.createdAt).toDateString()} icon={DateIcon} />
      </ListItem>

      {task.dueAt && (
        <Page.Section className='mt-4'>
          <div className={`${new Date(task.dueAt) <= new Date() ? c.over : ''}`}>
            <span className={c.title}>Due At</span>
            <div>
              <BiCalendarExclamation className={c.cal} />  {new Date(task.dueAt).toLocaleDateString()}
            </div>
          </div>
        </Page.Section>
      )}
      
      <div className='mt-4'>
        <WorkflowRunAssignment workflow_run_id={reportID} user_id={task.userId}/>
      </div>

      {/* Elements */}
      {task.steps?.sort((a, b) => a.parentStep.sortOrder - b.parentStep.sortOrder).map(step => (
        <div className='mt-4' key={step.id}>
          <ListItem className='d-flex flex-column align-items-start mb-4'>
            <h3>{step.parentStep.name}</h3>
            <p>{step.parentStep.description}</p>
          </ListItem>
          {/* TODO: Create renderer component for workflow run responses */}
          {step.responses?.sort((a, b) => a.element.sortOrder - b.element.sortOrder).map(response => (
            <ListItem className='d-flex flex-column align-items-start' key={response.id}>
              { response.element.type === 'DATETIME' && 
                <>
                  <h5>{response.element.name}</h5>
                  <p>{new Date(response.value).toLocaleString()}</p>
                </>
              }

              {response.element.type === 'FILE' &&
                <>
                  <h5>Uploaded File</h5>
                  <p><a href={response.value} target='_blank' rel="noreferrer">Click Here to View</a></p>
                </>
              }

              {response.element.type !== 'DATETIME' && response.element.type !== 'FILE' &&
                <>
                  <h5>{response.element.name}</h5>
                  <p>{response.value}</p>
                </>
              }
            </ListItem>
          ))}
        </div>
      ))}


      {/* Documents */}
      <ListItem className='mt-5'>
        <h3>Documents</h3>
      </ListItem>
      <Page.Hr />
      {task.workflow.documents?.map(document => (
        <a href={document.url} target="_blank" key={document.url} rel="noreferrer">
          <ListItem>
            <IconItem icon={FileIcon} text={document.title} />
          </ListItem>
        </a>
      ))}

      {/* Comments */}
      <ListItem className='mt-5'>
        <h3>Comments</h3>
      </ListItem>
      <Page.Hr />
      {messages?.map((eachMsg, index) => (
        <ListItem key={index} className={['mt-2']}>
          {eachMsg}
        </ListItem>
      ))}

      <div className="d-flex justify-content-end align-items-center mt-2">
      <Button onClick={() => history.push(`/message/${task.id}/${task.title}`)} className={c.actionButton} variant='info' size='small'>Make Comment</Button>
        <Button onClick={DeleteTask} className={c.actionButton} variant='warning' size='small'>Delete Task</Button>
        <Button onClick={() => history.push(`/task/${task.id}/progress`)} className={c.actionButton} variant='info' size='small'>Edit Task</Button>
      </div>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  removeUser: () => dispatch(userActions.removeUser()),
  flashMessage: (message) => dispatch(systemActions.flashMessage(message)),
})

export default connect(null, mapDispatchToProps)(TaskDetails)