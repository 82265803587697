import React from 'react'
import cn from 'classnames'
import c from "./IconItem.module.scss"

const IconItem = props => {
  const { text, textClass, icon, className, ...rest } = props
  return (
    <div className={cn(c.item, className)} {...rest} >
      <img className={c.icon} src={icon} alt=''/>
      <div className={cn(c.text, textClass)}>{text}</div>
    </div>
  )
}

export const IconItemP = props => {
  const { text, textClass, icon, className, ...rest } = props
  return (
    <span className={cn(c.item, className)} {...rest} >
      <img className={c.icon} src={icon} alt=''/>
      <span className={cn(c.text, textClass)}>{text}</span>
    </span>
  )
}

export default IconItem
