import React, { useState } from 'react'
import Badge from 'aius-comp/Badge'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import Page from '../components/Page'
import { systemActions } from '../store/actions'
import * as Yup from 'yup'
import axios from 'axios'
import { Field, Formik } from 'formik'
import Input from 'aius-comp/Input'
import Button from 'aius-comp/Button'

/*  
  Page used to set a new password.
  A link to this page with query parameters containing user's email and secret token is attached to 
  confirmation e-mail sent to the user on password change request.
*/

const ResetPassword = props => {
  const search = new URLSearchParams(useLocation().search)
  const history = useHistory()
  const [errorText, setErrorText] = useState("")

  if (!search.has('token') || !search.has('email')) {
    props.flashMessage('Link is missing information')
    history.push('/signin')
  }

  const token = search.get('token')
  const email = search.get('email')

  const passwordHint = "Password must contain at least 8 characters, one uppercase, one number and one special case character"
  const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/; //eslint-disable-line

  const handlePasswordChange = ({ password }) => {
    const data = { email, password, token }
    axios.post(`${process.env.REACT_APP_BACKEND_URL}/auth/reset-password`, data)
      .then(() => {
        props.flashMessage('Password updated ✔')
        history.push('/signin')
      })
      .catch(e => {
        console.error(e)
        setErrorText()
      })
  }
  
  return (
    <Page>
      <div className='d-flex align-items-center justify-content-center h-100'>
        <div className='d-flex flex-column flex-gap-1 w-100 p-5'>
          <h1>Enter new password</h1>
          {errorText && (
            <Badge pill variant='warning' className='text-wrap'>
              {errorText}
            </Badge>
          )}
          <Formik
            initialValues={{ password: "" }}
            onSubmit={handlePasswordChange}
            validationSchema={Yup.object({
              password: Yup.string()
                .required("Password is required")
                .matches(passwordRegEx, passwordHint),
              passwordMatch: Yup.string()
                .oneOf([Yup.ref("password")], "Passwords must match")
                .required("Required"),
            })}
          >
            {({ errors, handleSubmit }) => (
              <div onClick={() => setErrorText("")}>
                <Field name='password' error={errors.password} type='password' label='Password' as={Input} />
                <Field
                  name='passwordMatch'
                  error={errors.passwordMatch}
                  type='password'
                  label='Repeat Password'
                  as={Input}
                />
                <div className='d-flex flex-column  flex-gap-1 justify-content-center'>
                  <Button className='mt-4' wide onClick={handleSubmit}>
                    Set Password
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  flashMessage: message => dispatch(systemActions.flashMessage(message)),
})

export default connect(null, mapDispatchToProps)(ResetPassword)