import React from 'react'
import Button from 'aius-comp/Button'
import c from './Welcome.module.scss'
import { userActions } from "../store/actions"
import { connect } from "react-redux"

const Welcome = ({ history, setWelcomeVisited, name }) => {
  const handleClick = () => {
    setWelcomeVisited()
    history.push("/")
  }

  return (
    <div className={c.page}>
      <h3>Welcome, {name ?? "user"}</h3>
      <h1>Aius Protect</h1>
      <p>Proactive Protection</p>
      <Button className='mt-2' size='large' onClick={handleClick}>
        Go to Dashboard
      </Button>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setWelcomeVisited: () => dispatch(userActions.setWelcomeVisited()),
})

const mapStateToProps = state => ({
  name: state.user.firstName,
})

export default connect(mapStateToProps, mapDispatchToProps)(Welcome)
