import React, { useEffect, useRef } from "react"
import { Chart, PieController, ArcElement } from "chart.js"
import c from "./IncidentPieChart.module.scss"

/*
  Pie chart used for incident analytics
*/

Chart.register(PieController, ArcElement)

const RiskMaturityChart = ({ value }) => {
  const chartRef = useRef()

  useEffect(() => {
    const ref = chartRef.current.getContext("2d")
    const colors = ["#71ADBB", "#E0B259", "#e37bde", "#FF4A4A"]
    var color = colors[3]
    if (value < 36) color = colors[2]
    if (value < 24) color = colors[1]
    if (value < 12) color = colors[0]
    
    
    const data = {
      datasets: [
        {
          label: "Incidents",
          data: [value, 48-value],
          backgroundColor: [ color, '#F2F2F2'],
        },
      ],
    }
    const chart = new Chart(ref, {
      type: "pie",
      data: data,
      options: {
        responsive: true,
      },
    })
    return () => chart.destroy()
  }, [value])

  return (
    <div className={c.chart}>
      <canvas ref={chartRef}></canvas>
      <div className={c.middle}></div>
    </div>
  )
}

export default RiskMaturityChart
