import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Button from "aius-comp/Button"
import CloseHeader from "../components/CloseHeader"
import AssessmentStep from "../components/AssessmentStep"
import { Formik } from "formik"
import c from "./AssessmentRun.module.scss"
import axios from "axios"
import ErrorPage from "aius-comp/ErrorPage"
import Loading from "aius-comp/Loading"
import WorkflowExitPopup from "../components/WorkflowExitPopup"
import { readFile } from "aius-fn"

/*  
  Page for completing assessments.

  TODO: 
  - Conditionally show prompts for document submission
  - Render 'Select' element as radios instead of dropdown
*/

const AssessmentRun = props => {
  const { history } = props
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const { id } = useParams()
  const [run, setRun] = useState(undefined)

  const stepIndex = 1; // for Human readable index // props.location?.state?.stepIndex +

  // Continue or start a workflow run
  useEffect(() => {
    const run = props.location?.state?.run
    if (run) {
      setRun(run)
      setLoading(false)
    } else {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}/full`)
        .then(res => setRun(res.data?.workflowRun))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // If workflow loading
  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  // If not found
  if (!run) {
    console.error("Couldn't retrieve workflow")
    return <ErrorPage title='No workflow' description='Workfload could not be loaded' />
  }

  //console.log("[WorkflowRun] Run: ", run)

  const { workflow } = run
  const { steps, name } = workflow

  // If no steps
  if (!steps || steps.length === 0) {
    console.error("No steps")
    return <ErrorPage title='No steps' description="Workflow doesn't have any associated steps" />
  }

  // Sort steps by order
  steps.sort((step1, step2) => step1.sortOrder - step2.sortOrder)

  // Get initial step ID
  const stepID = props.location?.state?.stepID ?? steps[0].id

  const currentStep = steps.find(step => step.id === stepID)

  // If no steps
  if (!currentStep) {
    console.error(`No steps with ID of ${stepID}`)
    return <ErrorPage title='Step not found' description="Workflow doesn't have step with provided ID" />
  }

  // Get existing values for the step
  const existingStep = run.steps.find(step => step.workflowStepId === stepID)
  const existingResponses = existingStep?.responses ?? []

  //console.log("[WorkflowRun] Current step: ", currentStep)
  //console.log("[WorkflowRun] Existing responses: ", existingResponses)

  // Last step modal
  const openModal = () => {
    setShowModal(true)
  }

  // Cancel assessment
  const cancelAssessment = () => {
    history.push(`/assessments/${run.id}/progress`)
  }

  // Assessment submission
  const handleSubmit = async values => {

    setSubmitting(true)
    try {

      const data = {
        state: 'INPROGRESS',
        elements: [],
        files: [],
        title: values._title,
        severity: values._severity,
        occurredAt: values._occurredAt,
      }

      const runId = run.id

      delete values._severity
      delete values._occurredAt
      delete values._title


      for (const key in values) {  
        let element = values[key]
        if (Array.isArray(values[key])){
          try {
            //TODO we only look at the first file - which is fine atm because they can only select one
            const parsedFile = await readFile(element[0].value)
            data.files.push( { 
              'elementId' : element[0].elementId, 
              'stepId' : element[0].stepId, 
              'fileName' : parsedFile.fileName,
              'fileType' : parsedFile.fileType,
              'fileSize' : parsedFile.fileSize,
              'fileBase64' : parsedFile.fileBase64
            } )            
             
          } catch (err) {
            console.error("[ReportRun] Failed to parse attachment: ", err)
          } 
        } else {
          //console.log('not file')
          data.elements.push( { 'stepId' : element.stepId, 'elementId' : element.elementId, 'value': element.value} )
        }



      }
      // Submit workflow
      axios.patch(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${runId}`, data)
      setShowModal(false)
      setSubmitting(false)
      history.push(`/assessments/${runId}/progress`)
  
    } catch (e) {
      setSubmitting(false)
      props.flashMessage("Error occurred...")
      console.error(e)
    }


/*
    setSubmitting(true)

    // Find file fields
    const dropzones = steps.reduce(
      (acc, step) =>
        Array.isArray(step?.elements)
          ? acc.concat(step.elements.reduce((acc, element) => (element.type === "FILE" ? [...acc, element.fieldName] : acc), []))
          : acc,
      []
    )

    // Handle files
    const files = []
    for (const field of dropzones) {
      const dropzone = values[field]
      if (Array.isArray(dropzone)) {
        for (const _file of dropzone) {
          const dropzoneId = _file?.elementId
          const element = _file?.element
          const file = _file?.value
          if (file) {
            try {
              delete values[field]
              const parsedFile = await readFile(file)
              files.push({ ...parsedFile, element, elementId: dropzoneId })
            } catch (err) {
              console.warn("Failed to parse attachment: ", err)
            }
          }
        }
      }
    }

    // Aggregate by step
    let responseSteps = files.concat(Object.values(values)).reduce((acc, response) => {
      const { stepId, ...rest } = response
      if (!acc[stepId]) {
        acc[stepId] = []
      }
      acc[stepId].push(rest)
      return acc
    }, [])
    responseSteps = Object.keys(responseSteps).map(key => ({ id: key, elements: responseSteps[key] }))

    // Construct request data
    const id = run.id
    const state = 'INPROGRESS'
    const data = {
      state: state,
      steps: responseSteps,
    }

    console.log("[WorkflowRun] Submitting workflow run: ", data)

    // Submit assessment
    axios
      .patch(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}`, data)
      .then(() => {
        setShowModal(false)
        history.push(`/assessments/${id}/progress`)
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
      })*/
  }

  return (
    <div className={c.page}>
      {/* Main */}
      <CloseHeader title={name} onClose={openModal} />
      {/* Steps */}
      <Formik initialValues={{}} onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <>
            <AssessmentStep
              workflow={workflow}
              step={currentStep}
              responses={existingResponses}
              onSubmit={handleSubmit} 
              stepIndex={stepIndex}
            />

            {showModal && (
              <WorkflowExitPopup onClose={() => setShowModal(false)}>
                <div className='d-flex flex-column justify-content-end flex-gap-2 align-items-center'>
                  <Button onClick={() => setShowModal(false)} variant='grey'>Cancel</Button>
                  <Button onClick={() => cancelAssessment()} variant='warning'>Discard</Button>
                  <Button disabled={submitting} onClick={handleSubmit}>Save</Button>
                </div>
              </WorkflowExitPopup>
            )}
          </>
        )}
      </Formik>
    </div>
  )
}


export default AssessmentRun
