import React from 'react'
import documentIcon from '../images/icons/document.svg'
import c from './Document.module.scss'

const Document = ({ children }) => {
  return (
    <div className={c.doc}>
      <img src={documentIcon} className={c.icon} alt=''/>
      <div className={c.title}>{children}</div>
    </div>
  )
}

export default Document
