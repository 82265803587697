import React from "react";
import PropTypes from "prop-types";
import cn from 'classnames'
import c from './FileIcon.module.scss'

import ImageIcon from "./images/mime_type/img.svg"
import AudioIcon from "./images/mime_type/audio.svg"
import VideoIcon from "./images/mime_type/video.svg"
import PDFIcon from "./images/mime_type/pdf.svg"
import WordIcon from "./images/mime_type/word.svg"
import ExcelIcon from "./images/mime_type/excel.svg"
import PowerPointIcon from "./images/mime_type/powerpoint.svg"
import TextIcon from "./images/mime_type/text.svg"
import CodeIcon from "./images/mime_type/code.svg"
import ArchiveIcon from "./images/mime_type/archive.svg"
import EmptyIcon from "./images/mime_type/empty.svg"

const icons = {
  "image": ImageIcon,
  "audio": AudioIcon,
  "video": VideoIcon,
  "application/pdf": PDFIcon,
  "application/msword": WordIcon,
  "application/vnd.ms-word": WordIcon,
  "application/vnd.oasis.opendocument.text": WordIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml": WordIcon,
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": WordIcon,
  "application/vnd.ms-excel": ExcelIcon,
  "application/vnd.openxmlformats-officedocument.spreadsheetml": ExcelIcon,
  "application/vnd.oasis.opendocument.spreadsheet": ExcelIcon,
  "application/vnd.ms-powerpoint": PowerPointIcon,
  "application/vnd.openxmlformats-officedocument.presentationml": PowerPointIcon,
  "application/vnd.oasis.opendocument.presentation": PowerPointIcon,
  "text/plain": TextIcon,
  "text/html": CodeIcon,
  "application/json": CodeIcon,
  "application/gzip": ArchiveIcon,
  "application/zip": ArchiveIcon,
  "empty": EmptyIcon,
}

/**
    `import FileIcon from 'aius-comp/FileIcon'`
 */

export const FileIcon = ({size, mimeType, ...props}) => {
  mimeType = ['image', 'video', 'audio'].includes(mimeType.split('/')[0]) ? mimeType.split('/')[0] : mimeType

  let icon
  if (icons.hasOwnProperty(mimeType)) {
    icon = icons[mimeType]
  } else {
    icon = icons["empty"]
  }

  

  const sizes = {
    'large': [c.large],
    'normal': [c.normal],
    'small': [c.small],
  }

  const classes = cn(sizes[size])

  return <img src={icon} className={classes} {...props} alt=''/>
}

FileIcon.defaultProps = {
  mimeType: "empty",
  size: "normal",
}

FileIcon.propTypes = {
  mimeType: PropTypes.oneOf(Object.keys(icons)),
  size: PropTypes.oneOf(["large", "normal", "small"]),
}

export default FileIcon
