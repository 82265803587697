import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import ProgressBar from '../components/ProgressBar'
import Button from "aius-comp/Button"
import CloseHeader from "../components/CloseHeader"
import ReportStep from "../components/ReportStep"
import { Formik } from "formik"
import * as Yup from 'yup'
import axios from "axios"
import ErrorPage from "aius-comp/ErrorPage"
import Loading from "aius-comp/Loading"
import Page from "../components/Page"
import { readFile } from "aius-fn"
import c from "./ReportRun.module.scss"
import WorkflowExitPopup from "../components/WorkflowExitPopup"
import Input from "aius-comp/Input"
import Select from "aius-comp/Select"
import { connect } from "react-redux"
import { systemActions } from "../store/actions"


const TaskRun = props => {
  const { id } = useParams()
  const { history } = props
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [initialInfoEntered, setInitialInfoEntered] = useState(false)
  const [run, setRun] = useState(undefined)
  const [index, setIndex] = useState(undefined)

  // Continue or start a workflow run
  useEffect(() => {
    const run = props.location?.state?.run
    if (run) {
      setRun(run)
      setLoading(false)
    } else {
      axios.post(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}`)
        .then(res => setRun(res.data?.workflowRun))
        .catch(err => console.error(err))
        .finally(() => setLoading(false))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // If workflow loading
  if (loading) {
    return (
      <div className='d-flex justify-content-center align-items-center h-100'>
        <Loading size='large' />
      </div>
    )
  }

  // If not found
  if (!run) {
    return <ErrorPage title='No workflow' description='Workfload could not be loaded' />
  }

  const { workflow } = run
  const { steps, name } = workflow

  // If no steps
  if (!steps || steps.length === 0) {
    return <ErrorPage title='No steps' description="Workflow doesn't have any associated steps" />
  }

  // Sort steps by order
  steps.sort((step1, step2) => step1.sortOrder - step2.sortOrder)

  let currentStep
  if (index !== undefined) {
    currentStep = steps[index]
  } else {
    setIndex(0)
    currentStep = steps[0]
  }

  // If no steps
  if (!currentStep) {
    return <ErrorPage title='Step not found' description="Workflow doesn't have step with provided ID" />
  }

  // Get existing values for the step
  // const existingStep = run.steps.find(step => step.workflowStepId == stepID)
  // const responses = existingStep?.responses ?? []

  const maxSteps = steps.length
  const isLastStep = index === maxSteps - 1

  // Step pagination
  const changeStep = change => {
    return () => {
      const nextIndex = Math.min(maxSteps - 1, Math.max(0, index + change))
      if (index !== nextIndex) { setIndex(nextIndex) }
    }
  }

  // Cancel assessment
  const cancelAssessment = () => {
    const id = run.id
    const data = {
      state: 'CANCELLED',
    }
    axios.patch(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${id}`, data)
      .then(() => {
        setShowModal(false)
        history.push("/")
      })
      .catch(err => {
        console.error(err)
        setSubmitting(false)
      })
  }

  const submitHandler = (state = "CLOSED") => async values => {
    setSubmitting(true)
    try {
      const data = {
        state: state,
        elements: [],
        files: [],
        title: values._title,
        severity: values._severity,
        occurredAt: values._occurredAt,
        dueAt : values._dueAt
      }

      const runId = run.id

      delete values._severity
      delete values._occurredAt
      delete values._title
      delete values._dueAt


      for (const key in values) {  
        let element = values[key]
        if (Array.isArray(values[key])){
          try {
            //TODO we only look at the first file - which is fine atm because they can only select one
            const parsedFile = await readFile(element[0].value)
            data.files.push( { 
              'elementId' : element[0].elementId, 
              'stepId' : element[0].stepId, 
              'fileName' : parsedFile.fileName,
              'fileType' : parsedFile.fileType,
              'fileSize' : parsedFile.fileSize,
              'fileBase64' : parsedFile.fileBase64
            } )            
             
          } catch (err) {
            console.error("[TaskRun] Failed to parse attachment: ", err)
          } 
        } else {
          data.elements.push( { 'stepId' : element.stepId, 'elementId' : element.elementId, 'value': element.value} )
        }



      }
      // Submit workflow
      axios.patch(`${process.env.REACT_APP_BACKEND_URL}/workflow-runs/${runId}`, data).then(res => {
        setShowModal(false)
        setSubmitting(false)
        props.flashMessage('Task submitted ✅')
        //history.push(`/assign-user/${res.data.workflowRun.id}`, { next: '/new-tasks' })
        history.push(`/new-tasks`)
      })
    } catch (e) {
      setSubmitting(false)
      props.flashMessage("Error occurred...")
      console.error(e)
    }
  }

  const next = changeStep(1)
  const prev = changeStep(-1)

  return (
    <Page>
      <Page.Section>
        {/* Top */}
        <CloseHeader title={name} onClose={() => setShowModal(true)} />
        <ProgressBar current={index} max={steps.length - 1} className='mb-5' />

        {/* Steps */}
        <Formik
          initialValues={{
            _title: "Task Run: " + name,
            _severity: "",
            _occurredAt: "",
            _dueAt: ""
          }}
          validationSchema={Yup.object({
            _title: Yup.string().required(),
            _severity: Yup.string().required(),
            _occurredAt: Yup.date().required()
          })}
          onSubmit={submitHandler("CLOSED")}
          validateOnMount={true}
        >
          {({ handleSubmit, setFieldValue, isValid, values, initialValues }) => (
            <>
              {/* Steps */}
              <div className={c.steps}>
                <ReportStep step={currentStep} responses={undefined} />
              </div>

              {/* Navigation */}
              <div className={c.bottom}>
                <Button onClick={prev} text>
                  Back
                </Button>
                {isLastStep ? (
                  <Button onClick={handleSubmit} disabled={submitting}>
                    Submit
                  </Button>
                ) : (
                  <Button onClick={next}>Next</Button>
                )}
              </div>

              {/* Pop-up */}
              {showModal && (
                <WorkflowExitPopup onClose={() => setShowModal(false)}>
                  <div className='d-flex flex-column justify-content-end flex-gap-2 align-items-center'>
                    <Button onClick={() => setShowModal(false)} variant='grey'>
                      Cancel
                    </Button>
                    <Button onClick={() => cancelAssessment()} variant='warning'>
                      Discard
                    </Button>
                    <Button disabled={submitting} onClick={() => submitHandler("INPROGRESS")(values)}>
                      Save
                    </Button>
                  </div>
                </WorkflowExitPopup>
              )}

              {/* Initial Information Pop-up */}
              {!initialInfoEntered && (
                <div className={c.initialPopupOverlay}>
                  <div className={c.initialPopup}>
                    <h1 className='mb-5'>Enter preliminary information</h1>
                    <div className='d-flex flex-column flex-gap-2 mb-5'>
                      <Input 
                        label="Task title"
                        value={initialValues._title}
                        onChange={e => setFieldValue("_title",e.target.value)}
                      />
                      <Select
                        label='Task outcome severity'
                        isSearchable={false}
                        options={[
                          { value: "MINOR", label: "Minor" },
                          { value: "MAJOR", label: "Major" },
                          { value: "SEVERE", label: "Severe" },
                          { value: "CRISIS", label: "Crisis" },
                        ]}
                        onChange={e => setFieldValue("_severity", e.value)}
                      />
                      <Input label='When task carried out' type='datetime-local' onChange={e => setFieldValue("_occurredAt", e.target.value)} />
                      <Input 
                        label="Due At"
                        onChange={e => setFieldValue("_dueAt", e.target.value)}
                        type='datetime-local'
                      />
                    </div>
                    <div className='d-flex justify-content-between align-items-center mt-2'>
                      <Button size="small" variant='warning' onClick={() => cancelAssessment()}>
                        Discard
                      </Button>
                      <Button size="normal" disabled={!isValid} onClick={() => setInitialInfoEntered(true)}>
                        Continue
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </Formik>
      </Page.Section>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  flashMessage: message => dispatch(systemActions.flashMessage(message)),
})

export default connect(null, mapDispatchToProps)(TaskRun)