import { useState, useEffect } from "react";
import axios from "axios";
import Button from "aius-comp/Button";
import Can from "../Can";
import { useHistory } from "react-router";
import c from "./AssessmentAssignments.module.scss";
import { RiBarChartGroupedFill, RiHotelLine } from "react-icons/ri";
import IconItem from "../IconItem";
import ProgressBar from "../ProgressBar";
import ControlSelect from "../ControlSelect";

const AssessmentAssignments = (props) => {
    const [selectedValues, setSelectedValues] = useState([]);
    const [summery, setSummery] = useState([]);
    const [allSummery, setAllSummery] = useState([]);
    const history = useHistory();
    useEffect(() => {
        axios
            .get(
                `${process.env.REACT_APP_BACKEND_URL}/locations/assignable/${props.workflowId}`
            )
            .then((result) => {
                setSelectedValues(result.data.selectedValues);
                setSummery(result.data.propertySpecificSummeries);
                setAllSummery(result.data.propertySpecificSummeries);
            });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const filterHandler = (filterWith) => {
        if(filterWith === ''){
            setSummery(allSummery);
        } else if(filterWith === 'not started') {
            const filterSummery = Object.keys(allSummery).map((key) => {
                const eachSummery = allSummery[key];
                return typeof eachSummery.run === 'undefined' ? eachSummery : null
            });
            setSummery(filterSummery)
        } else if(filterWith === 'passed') { 
            const filterSummery = Object.keys(allSummery).map((key) => {
                const eachSummery = allSummery[key];
                if(typeof eachSummery.run !== 'undefined'){
                    return eachSummery.run.totalSteps === eachSummery.run.passedSteps ? eachSummery : null
                }

                return null;
            });
            setSummery(filterSummery)
        } else if(filterWith === 'incomplete') { 
            const filterSummery = Object.keys(allSummery).map((key) => {
                const eachSummery = allSummery[key];
                if(typeof eachSummery.run !== 'undefined'){
                    return eachSummery.run.totalSteps !== eachSummery.run.completedSteps ? eachSummery : null
                }

                return null;
            });
            setSummery(filterSummery)
        } else if(filterWith === 'complete') { 
            const filterSummery = Object.keys(allSummery).map((key) => {
                const eachSummery = allSummery[key];
                if(typeof eachSummery.run !== 'undefined'){
                    return eachSummery.run.totalSteps === eachSummery.run.completedSteps ? eachSummery : null
                }

                return null;
            });
            setSummery(filterSummery)
        }
        
    }
    return (
        <div className={c.container}>
            <div className="d-flex justify-content-between align-items-center mt-2">
                <span className={c.heading}>Assigned Properties</span>
                <Can perform="assessments-assignment:modify">
                    <Button
                        size="tiny"
                        variant="grey"
                        onClick={() => {
                            history.push(
                                `/assessments/assignments/${props.workflowId}`
                            );
                        }}
                    >
                        Edit Assignments
                    </Button>
                </Can>
            </div>

            <div className="d-flex flex-column flex-gap-1 mb-4 mt-5">
                {selectedValues &&
                    selectedValues.map((value) => {
                        return (
                            <div className={c.property} key={value.value}>
                                {value.value.includes("propertyGroups") ? (
                                    <RiBarChartGroupedFill />
                                ) : (
                                    <RiHotelLine />
                                )}{" "}
                                {value.label}
                            </div>
                        );
                    })}
            </div>

            
            <div className="d-flex flex-column mt-4">

                <Can perform='hotels:aggregate'>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                        <span></span> 
                        <ControlSelect onChange={e => filterHandler(e.target.value)} defaultValue=''>
                            <option value=''>All</option>
                            <option value='passed'>Passed</option>
                            <option value='incomplete'>Incomplete</option>
                            <option value='complete'>Complete</option>
                            <option value='not started'>Not Started</option>
                        </ControlSelect>
                    </div>
                </Can>

                {summery &&
                    Object.keys(summery).map((key) => {
                        const eachSummery = summery[key];

                        const completedSteps = eachSummery?.run?.completedSteps
                            ? eachSummery?.run?.completedSteps
                            : 0;
                        const passedSteps = eachSummery?.run?.passedSteps
                            ? eachSummery?.run?.passedSteps
                            : 0;
                        const totalSteps = eachSummery?.run?.totalSteps
                            ? eachSummery?.run?.totalSteps
                            : 0;
                        if(eachSummery === null) {
                            return <div key={key}></div>;
                        } 
                        return (
                            <div key={key} className={c.progression}>
                                <IconItem
                                    icon={eachSummery?.property?.image_url}
                                    text={eachSummery?.property?.name}
                                />
                                <div className={c.fadeText}>
                                    Completed Steps: {completedSteps}/{totalSteps}
                                    <div>{`Passed / Failed: ${passedSteps}/${completedSteps - passedSteps}`}</div>
                                    <div style={{ width: "150px" }}>
                                        <ProgressBar
                                            current={[passedSteps, completedSteps - passedSteps]}
                                            max={totalSteps}
                                            state={['healthy', 'warning']}
                                        />
                                        
                                    </div>
                                </div>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

export default AssessmentAssignments;
