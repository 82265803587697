import React, { useEffect, useState } from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { createBrowserHistory } from "history"
import PrivateRoute from "./components/PrivateRoute"
import Welcome from "./pages/Welcome"
import Dashboard from "./pages/Dashboard"
import Actions from "./pages/Actions"
import SignUp from "./pages/SignUp"
import SignIn from "./pages/SignIn"
import IncidentReports from "./pages/IncidentReports"
import IncidentDetails from "./pages/IncidentDetails"
import Risks from "./pages/Risks"
import RiskRecommendations from "./pages/RiskRecommendations"
import ReportBegin from "./pages/ReportBegin"
import ReportRun from "./pages/ReportRun"
import ReportProgress from "./pages/ReportProgress"
import Assessments from "./pages/Assessments"
import AssessmentBegin from "./pages/AssessmentBegin"
import AssessmentRun from "./pages/AssessmentRun"
import AssessmentProgress from "./pages/AssessmentProgress"
import Trainings from "./pages/Trainings"
import TrainingBegin from "./pages/TrainingBegin"
import TrainingRun from "./pages/TrainingRun"
import TrainingProgress from "./pages/TrainingProgress"
import AssignTrainings from "./pages/AssignTrainings"

import Tasks from "./pages/Tasks"
import TaskDetails from "./pages/TaskDetails"
import NewTasks from "./pages/NewTasks"
import AssignTasks from "./pages/AssignTasks"
import AssignWorkflow from "./pages/AssignWorkflow"
import TaskBegin from "./pages/TaskBegin"
import TaskRun from "./pages/TaskRun"
import TaskProgress from "./pages/TaskProgress"


import Hotels from "./pages/Hotels"
import HotelDetails from "./pages/HotelDetails"
import HotelDetailsEdit from "./pages/HotelDetailsEdit"
import HotelAllDetails from "./pages/HotelAllDetails"
import DocumentLibrary from "./pages/DocumentLibrary"
import DocumentDetails from "./pages/DocumentDetails"
import DocumentNew from "./pages/DocumentNew"
import FlashMessage from "./components/FlashMessage"
import ForgotPassword from "./pages/ForgotPassword"
import ResetPassword from "./pages/ResetPassword"
import HotelAnalytics from "./pages/HotelAnalytics"
import MultipleHotelsAnalytics from "./pages/MultipleHotelsAnalytics"
import AlertWorkflow from "./pages/AlertWorkflow"
import ScrollToTop from './components/ScrollToTop'
import { connect } from "react-redux"
import { systemActions } from "./store/actions"
import UserProfile from "./pages/UserProfile"
import WorkflowMessage from "./pages/WorkflowMessage"
import CreateAssessments from "./pages/CreateAssessments"
import CreateAssessmentStep from "./pages/CreateAssessmentStep"
import AssessmentQuestion from "./pages/AssessmentQuestion"
import AssessmentAssignments from "./pages/AssessmentAssignments"
import TabDashboard from "./pages/tablayouts/TabDashboard"
import TabRisks from "./pages/tablayouts/TabRisks"
import ProfileMessage from "./pages/ProfileMessage"
import ProfileMessageDetails from "./pages/ProfileMessageDetails"
import Mail from "./pages/Mail"
import WRAssignments from "./pages/WRAssignments"
import NewTraining from "./pages/NewTraining"

const NOTIFICATION_INTERVAL = 1000 * 60
const WIDTH_OFFSET = 641;

const App = ({ authenticated, fetchNotifications, fetchMsgCount }) => {
  const history = createBrowserHistory()
  const [isTablet, setTsTablet] = useState(window.innerWidth > WIDTH_OFFSET);

  const updateMedia = () => {
    setTsTablet(window.innerWidth > WIDTH_OFFSET);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });


  useEffect(() => {
    if (authenticated) {
      fetchNotifications()
      fetchMsgCount()
    }
    const ID = setInterval(() => {
      if (authenticated) {
        fetchNotifications()
        fetchMsgCount()
      } else {
        clearInterval(ID)
      }
    }, NOTIFICATION_INTERVAL)
    return () => clearInterval(ID)
  }, [authenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Router history={history}>
        <ScrollToTop />
        <Switch>
          <PrivateRoute path='/' exact component={isTablet ? TabDashboard : Dashboard} />
          <PrivateRoute path='/welcome' component={Welcome} />
          <PrivateRoute path='/actions' component={Actions} />

          {/* User */}
          <PrivateRoute path='/profile' exact component={UserProfile} />

          {/* Documents */}
          <PrivateRoute path='/documents' exact component={DocumentLibrary} />
          <PrivateRoute path='/documents/new' exact component={DocumentNew} />
          <PrivateRoute path='/documents/:id' component={DocumentDetails} />

          {/* Reports */}
          <PrivateRoute path='/reports' exact component={IncidentReports} />
          <PrivateRoute path='/reports/:id' component={IncidentDetails} />

          {/* Hotels */}
          <PrivateRoute path='/hotels' exact component={Hotels} />
          <PrivateRoute path='/hotels/analytics' component={MultipleHotelsAnalytics} />
          <PrivateRoute path='/hotels/risks' component={TabRisks} />
          <PrivateRoute path='/hotels/:id' exact component={HotelDetails} />
          <PrivateRoute path='/hotels/:id/all-details' component={HotelAllDetails} />
          <PrivateRoute path='/hotels/:id/edit' component={HotelDetailsEdit} />
          <PrivateRoute path='/hotels/:id/analytics' component={HotelAnalytics} />

          {/* Assessments */}
          <PrivateRoute path='/assessments' exact component={Assessments} />
          <PrivateRoute path='/assessments/c/:id' exact component={Assessments} />
          <PrivateRoute path='/assessments/:id' exact component={AssessmentBegin} />
          <PrivateRoute path='/assessments/:id/run' component={AssessmentRun} />
          <PrivateRoute path='/assessments/:id/progress' component={AssessmentProgress} />
          <PrivateRoute path='/assessments/:id/assignment' component={AssessmentAssignments} />
          <PrivateRoute path='/create-assessments' component={CreateAssessments} /> 
          <PrivateRoute path='/create-assessments-step' component={CreateAssessmentStep} />
          <PrivateRoute path='/assessments-question' component={AssessmentQuestion} />
          <PrivateRoute path='/assessments/assignments/:id' exact component={AssessmentAssignments} />
          {/* Trainings */}
          <PrivateRoute path='/new-training' exact component={NewTraining} />
          <PrivateRoute path='/assign-trainings' component={AssignTrainings} />
          <PrivateRoute path='/assign-training/:id' component={AssignWorkflow} />
          <PrivateRoute path='/training' exact component={Trainings} />
          <PrivateRoute path='/trainings/:id' exact component={TrainingBegin} />
          <PrivateRoute path='/trainings/:id/run' component={TrainingRun} />
          <PrivateRoute path='/trainings/:id/progress' component={TrainingProgress} />

          {/* Tasks */}
          <PrivateRoute path='/tasks' exact component={Tasks} />
          <PrivateRoute path='/tasks/:id' component={TaskDetails} />
          <PrivateRoute path='/new-tasks' component={NewTasks} />
          <PrivateRoute path='/assign-tasks' component={AssignTasks} />
          <PrivateRoute path='/assign-task/:id' component={AssignWorkflow} />
          <PrivateRoute path='/task/:id' exact component={TaskBegin} />
          <PrivateRoute path='/task/:id/run' component={TaskRun} />
          <PrivateRoute path='/task/:id/progress' component={TaskProgress} />


          <PrivateRoute path='/assign-user/:id' exact component={WRAssignments} />

          {/* Reports */}
          <PrivateRoute path='/reports' exact component={IncidentReports} />
          <PrivateRoute path='/reports/:id' component={IncidentDetails} />
          <PrivateRoute path='/report/:id' exact component={ReportBegin} />
          <PrivateRoute path='/report/:id/run' component={ReportRun} />
          <PrivateRoute path='/report/:id/progress' component={ReportProgress} />

          {/* Alert Action */}
          <PrivateRoute path='/alert-action/:id' component={AlertWorkflow} />

          {/* Risks */}
          <PrivateRoute path='/risks/:id' exact component={Risks} />
          <PrivateRoute path='/risks/political' exact component={RiskRecommendations} />

          {/* Workflow Messages */}
          <PrivateRoute path='/message/:id/:title' exact component={WorkflowMessage} />
          {/* Profile Messages */}
          <PrivateRoute path='/mail/inbox' exact component={Mail}/>
          <PrivateRoute path='/mail/outbox' exact component={Mail}/>
          <PrivateRoute path='/profile-message' exact component={ProfileMessage}/>
          <PrivateRoute path='/profile-message/:id' exact component={ProfileMessageDetails}/>

          {/* Auth */}
          <Route path='/signup' component={SignUp} /> 
          <Route path='/signin' component={SignIn} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/reset-password' component={ResetPassword} />
        </Switch>
      </Router>
      <FlashMessage />
    </>
  )
}

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
})

const mapDispatchToProps = dispatch => ({
  fetchNotifications: () => dispatch(systemActions.fetchNotifications()),
  fetchMsgCount: () => dispatch(systemActions.fetchMsgCount())
})

export default connect(mapStateToProps, mapDispatchToProps)(App)
