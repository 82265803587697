import Button from "aius-comp/Button";
import { Input } from "aius-comp/Input";
import InputBox from "../../library/InputBox";

const PreliminaryForm = (props) => {
    return (
        <>
            <h1 className="mb-5">Enter preliminary information</h1>
            <div className="d-flex flex-column flex-gap-2 mb-5">
                <Input
                    name="name"
                    label="name"
                />
                <InputBox 
                    name="description"
                    label="description"
                />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
                <Button
                    size="small"
                    variant="warning"
                    onClick={() => {props.setOpen(false)}}
                >
                    Discard
                </Button>
                <Button
                    size="small"
                    onClick={() => {props.setOpen(false)}}
                >
                    Continue
                </Button>
            </div>
        </>
    );
};

export default PreliminaryForm;
