/**
 * Takes bytes and returns string representing size in a readable format
 * 
 * source: https://web.archive.org/web/20120507054320/http://codeaid.net/javascript/convert-size-in-bytes-to-human-readable-format-(javascript)
 * 
 * @param {integer} bytes 
 * @returns {str}
 */

export const bytesToSize = bytes => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) { 
    return 'n/a'
  }
  const index = parseInt( Math.floor(Math.log(bytes) / Math.log(1024)) )
  return (bytes / Math.pow(1024, index)).toFixed(1) + ' ' + sizes[index]
}

export default bytesToSize