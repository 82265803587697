import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import IncidentBreakdown from './IncidentBreakdown'
import c from './HotelCard.module.scss'
import axios from 'axios'

/*
  Used in Hub page to display assigned hotel
*/

const HotelCard = ({ hotel }) => {
  const history = useHistory()
  const [statistics, setStatistics] = useState([])

  // Get incidents for 7 days | Default
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents/summary`, { days: 7 })
      .then(res => setStatistics(res.data.statistics))
      .catch(e => console.warn(e))
    
      return () => { setStatistics([]) }
  }, [])

  return (
    <div
      className={c.card}
      style={{ backgroundImage: `url(${hotel.coverImageURL})` }}
      onClick={() => history.push(`hotels/${hotel.id}/analytics`, { hotel })}
    >
      <div className={c.overlay}>
        <IncidentBreakdown
          dashboard
          minor={statistics?.minor ?? 0}
          major={statistics?.major ?? 0}
          severe={statistics?.severe ?? 0}
          crisis={statistics?.crisis ?? 0}
        />
      </div>
    </div>
  )
}
export default HotelCard
