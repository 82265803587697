import React from 'react'
import cn from 'classnames'
import c from './ListItem.module.scss'

const ListItem = ({ children, className, bordered, ...rest }) => {
  return (
    <div {...rest} className={cn(c.item, { [c.bordered]: bordered }, className)}>
      {children}
    </div>
  )
}

export default ListItem
