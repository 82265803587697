import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import c from './ProgressBar.module.scss'

const ProgressBar = ({ current, max, state, size, className }) => {
  let percentageArr;
  let barClassesArr;
  if(typeof current === 'number'){
    const percentage = max !== 0 ? Math.round((current / max) * 100) : 0;
    percentageArr = [percentage];
  } else {
    percentageArr = current.map((e)=>{
      return max !== 0 ? Math.round((e / max) * 100) : 0;
    })
  }

  const stateClasses = {
    healthy: c.healthy,
    warning: c.warning,
    incomplete: c.incomplete,
  }
  const sizeClasses = {
    normal: c.normal,
    small: c.small
  }

  

  if(typeof state === 'string'){
    const barClasses = cn([c.bar, stateClasses[state]])
    barClassesArr = [barClasses];
  } else {
    barClassesArr = state.map((e) => {
      return cn([c.bar, stateClasses[e]]);
    })
  }


  const progressClasses = cn([c.progress, sizeClasses[size], className])
  
  return (
    <div className={progressClasses}>
      {percentageArr.map((e, i) => {
        
        return <div key={i} className={barClassesArr[i]} style={{ width: `${e}%` }}></div>
      })}
    </div>
  )
}

ProgressBar.propTypes = {
  state: PropTypes.any ,
  size: PropTypes.oneOf(["normal", "small"]),
}

ProgressBar.defaultProps = {
  current: 0,
  max: 100,
  state: "healthy",
  size: "normal",
}




export default ProgressBar
