import React, { useState } from 'react'
import Input from 'aius-comp/Input'
import Badge from "aius-comp/Badge"
import Button from 'aius-comp/Button'
import { Formik, Field } from "formik"
import * as Yup from "yup"

/*  
  Currently not in use. Users might have to be registered by account administrator.
*/

const SignUp = props => {
  const { history } = props
  const [errorText, setErrorText] = useState("")
  
  const handleSignUp = ({ username, email, password}) => {
    // TODO
  }

  return (
    <div className='d-flex align-items-center justify-content-center h-100'>
      <div className='d-flex flex-column flex-gap-1 w-100 p-5'>
        <h1>Create new account</h1>
        {errorText && (
          <Badge pill variant='warning' className='text-wrap'>
            {errorText}
          </Badge>
        )}
        <Formik
          initialValues={{ username: "", email: "", password: "" }}
          onSubmit={handleSignUp}
          validationSchema={Yup.object({
            username: Yup.string().required().required("Username is required"),
            email: Yup.string().required().required("Email is required"),
            password: Yup.string().required("Password is required"),
          })}
        >
          {({ errors, handleSubmit }) => (
            <div onClick={() => setErrorText("")}>
              <Field name='username' error={errors.username} label='Username' as={Input} />
              <Field name='email' error={errors.email} label='Email' as={Input} />
              <Field name='password' error={errors.password} type='password' label='Password' as={Input} />
              <Button className='mt-4' wide onClick={handleSubmit}>
                Sign up
              </Button>
            </div>
          )}
        </Formik>
        <Button light onClick={() => history.push("/signin")}>
          Log in
        </Button>
      </div>
    </div>
  )
}

export default SignUp
