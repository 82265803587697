import React, { useState, useEffect } from "react";
import c from "./Popup.module.scss";
const Popup = (props) => {
    const [isOpen, setIsOpen] = useState(false);
    const { defaultState } = props;
    useEffect(() => {
        setIsOpen(defaultState === "open");
    }, [defaultState]);
    return (
        isOpen && (
            <div className={c.initialPopupOverlay}>
                <div className={c.initialPopup}>
                    {React.cloneElement(props.children, { setOpen: setIsOpen })}
                </div>
            </div>
        )
    );
};

export default Popup;
