import React from 'react'
import { connect } from  'react-redux'
import { Route, Redirect } from "react-router-dom"


const PrivateRoute = ({ children, authenticated, ...props }) => {
  return (
    <Route {...props}>
      { authenticated ? children : <Redirect to='/signin' /> }
    </Route>
  )
}

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
})

export default connect(mapStateToProps)(PrivateRoute)
