import types from "../types/hotels"
import axios from "axios"

export const fetchHotels = () => {
  return dispatch => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations`)
      .then(res => {
        dispatch({ type: types.fetchHotels, payload: res.data.locations })
      })
      .catch(err => console.error(err))
  }
}

export const removeHotels = () => {
  return { type: types.removeHotels }
}

/*
export default {
  fetchHotels,
  removeHotels,
}
*/
