import React from "react"
import cn from 'classnames'
import c from "./HotelRiskItem.module.scss"
import RiskMaturityChart from '../components/RiskMaturityChart'

const HotelRiskItem = props => {
  const {
    id,
    name,
    city,
    risks,
    className,
    ...rest
  } = props
  
  return (
    <div className={cn(c.item, className)} {...rest}>    
      <div className={c.imageContainer}>
        <RiskMaturityChart value={risks.risk_rating}/>
        <div className={c.label}>{risks.risk_rating}</div>
      </div>
      
      <div className={c.details}>
        <div className={c.name}>{name}</div>
        <div className={c.city}>{city}</div>
      </div>
    </div>
  )
}

export default HotelRiskItem