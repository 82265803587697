import React, { useEffect, useState } from 'react'
import { GoogleMap, LoadScript } from "@react-google-maps/api"
import IncidentBreakdown from './IncidentBreakdown'
import { useHistory } from 'react-router'
import c from './MultipleHotelsCard.module.scss'
import axios from 'axios'

const MultipleHotelsCard = () => {
  const history = useHistory()
  const [statistics, setStatistics] = useState([])
  const GMAP_KEY = process.env.REACT_APP_GMAPS_API_KEY

  // Get incidents for 7 days
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/incidents/daily`, { days: 7 })
      .then(res => setStatistics(res.data.statistics))
      .catch(e => console.error(e))

    return () => { setStatistics([]) }
  }, [])

  const center = {
    lat: 50.0,
    lng: 0.4,
  }

  const mapStyles = {
    width: "100%",
    height: "100%",
  }

  const mapOptions = {
    disableDefaultUI: true,
  }

  const sum = array => (Array.isArray(array) ? array.reduce((a, b) => a + b, 0) : 0)

  return (
    <div className={c.card}>
      <div className={c.map}>
        <LoadScript googleMapsApiKey={GMAP_KEY}>
          <GoogleMap mapContainerStyle={mapStyles} center={center} zoom={4} options={mapOptions}></GoogleMap>
        </LoadScript>
      </div>
      <div className={c.overlay} onClick={() => history.push("/hotels/analytics")}>
        <IncidentBreakdown
          dashboard
          minor={sum(statistics?.minor)}
          major={sum(statistics?.major)}
          severe={sum(statistics?.severe)}
          crisis={sum(statistics?.crisis)}
        />
      </div>
    </div>
  )
}

export default MultipleHotelsCard