import React, { useState, useEffect } from "react"
//import { connect } from 'react-redux'
import CloseHeader from '../components/CloseHeader'
import ListItem from "../components/ListItem"
import Page from "../components/Page"
import Expandable from "../components/Expandable"
import Search from "aius-comp/Search"
import c from "./Actions.module.scss"
import axios from "axios"

const NewTraining = props => {
  const { history } = props
  const [isExpanded, setIsExpanded] = useState(false);
  const [filter, setFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const [hero, setHero] = useState([]);
  const [regular, setRegular] = useState([]);
  const [incidents, setIncidents] = useState([]);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/workflows`)
      .then(res => {
        
        const workflows = res.data.workflows 
        const incidents = workflows?.filter(workflow => workflow.type === "TRAINING") ?? []
        
        setFilteredData(incidents)
        setIncidents(incidents)
        const { hero, ...regular } = incidents.reduce((groups, incident) => {
          if (incident.hero) {
            if (!groups['hero']) { groups['hero'] = [] }
            groups['hero'].push(incident)
          } else {
            incident.categories.forEach(({ category, type }) => {
              if(type.toUpperCase() === 'TRAINING'){
                if (!groups[category]) { groups[category] = [] }
                groups[category].push(incident)
              }
            })
          }
          return groups
        }, {})
        setHero(hero)
        setRegular(regular)
      })
      .catch(err => console.error(err))
  }, [])


  const handleSearchKeyUp = e => {
    const searchValue = e.target.value
    if( searchValue.length > 0 ){
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
    setFilter(searchValue);
  }


  useEffect(() => {
    const lowercasedFilter = filter.toLowerCase();
    const filteredData = incidents.filter(item => {
      return item.name.toLowerCase().includes(lowercasedFilter)
    });
    setFilteredData(filteredData)
  }, [filter, incidents])

  
  return (
    <Page dark>
      <Page.Section>
        {/* Close header */}
        <CloseHeader title='New Training' onClose={() => history.push("/")} />

        {/* List | Common incidents */}
        <div className={c.workflows}>
          {hero?.map(incident => (
            <div className={c.workflow} key={incident.id} onClick={() => history.push(`/trainings/${incident.id}`)}>
              {incident.name}
            </div>
          ))}
        </div>

        {/* Search Workflows */}
        <Search onChange={handleSearchKeyUp} />
      </Page.Section>

      {/*  List | All Incidents */}
      <div className='mt-4'>
        
        
        {isExpanded && filteredData.map(incident => (
          <div key={incident.id}>
            <Page.Hr />
            <ListItem key={incident.id} onClick={() => history.push(`/trainings/${incident.id}`)}>
              {incident.name}
            </ListItem>
          </div>
        ))}

        { isExpanded && filteredData.length === 0 ? (<ListItem><span className={c.warning}>No item found, Please search with another keyword or expand categories below</span></ListItem>) : ''}

        {( (isExpanded && filteredData.length === 0) || !isExpanded ) && Object.entries(regular).map(([category, incidents]) => (
          <Expandable text={category} key={category}>
            {incidents.map(incident => (
              <ListItem key={incident.id} onClick={() => history.push(`/trainings/${incident.id}`)}>
                {incident.name}
              </ListItem>
            ))}
          </Expandable>
        ))
        }
        
      </div>
    </Page>
  )
}

/*
const mapStateToProps = state => {
  return {
    trainings: state.workflows.trainings,
  }
}
*/
export default NewTraining//connect(mapStateToProps)(NewTraining)
