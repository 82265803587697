import React from 'react'
import cn from 'classnames'
import c from './SeverityCircle.module.scss'

const severities = {
  crisis: c.crisis,
  severe: c.severe,
  major: c.major,
  minor: c.minor,
  none: c.none,
}

const SeverityCircle = ({ severity }) => {
  const circleClasses = cn(c.circle, severities[severity])
  return <div className={circleClasses}></div>
}

export default SeverityCircle
