import React from 'react'
import cn from 'classnames'
import c from "./BaseTaskItem.module.scss"

const BaseTaskItem = ({ 
  title, 
  subtitle, 
  children,
  className,
  severity,
  ...rest 
}) => {
  
  const severities = {
    'crisis': c.crisis,
    'severe': c.severe,
    'major': c.major,
    'minor': c.minor,
    'none': c.none
  }

  const severityClass = Object.keys(severities).includes(severity) ? severities[severity] : severities['none']

  return (
    <div className={c.container}>
      <div className={cn(c.bar, severityClass)}></div>
      <div className={cn(c.task, className)} {...rest}>
        <div className={c.title}>{title}</div>
        <div className={c.subtitle}>{subtitle}</div>
        {children && <div className='w-100'>{children}</div>}
      </div>
    </div>
  )
}



export default BaseTaskItem
