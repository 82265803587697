import types from "../types/hotels"

export const hotels = (
  state = { 
    list: [], 
    fetched: false 
  }, { type, payload }) => {
  switch (type) {
    case types.fetchHotels:
      return {
        ...state,
        list: payload,
        fetched: true,
      }
    case types.removeHotels:
      return {
        ...state,
        list: [],
        fetched: false,
      }
    default:
      return state
  }
}

export default hotels
