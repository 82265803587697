import React, { useEffect, useState } from "react"
import Page, { useMenu } from "../components/Page"
import TasksToday from "../components/TasksToday"
import DashboardSummary from "../components/DashboardSummary"
import Header from "../components/Header"
import Button from "aius-comp/Button"
import Search from "aius-comp/Search"
import { hotelActions, systemActions, workflowActions } from "../store/actions"
import { Redirect, useHistory } from "react-router-dom"
import { connect } from "react-redux"
import HotelCard from "../components/HotelCard"
import MultipleHotelsCard from "../components/MultipleHotelsCard"
import Can from "../components/Can"
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import axios from "axios"
import SearchResultList from "../components/SearchResultList"

const Dashboard = ({ 
  isWelcomeVisited, 
  fetchWorkflows, 
  fetchHotels,
  fetchNotifications,
  notificationsFetched,
  workflowsFetched, 
  hotelsFetched, 
  hotels 
}) => {
  const [openMenu, controls] = useMenu()
  const history = useHistory()
  const [hubResult, setHubResult] = useState([])
  const [dropdownVisible, setDropdownVisible] = useState(false)

  // Fetch workflows
  useEffect(() => {
    if (!workflowsFetched) {
      fetchWorkflows()
    }
  }, [workflowsFetched, fetchWorkflows])

  // Fetch hotels
  useEffect(() => {
    if (!hotelsFetched) {
      fetchHotels()
    }
  }, [hotelsFetched, fetchHotels])

  // Fetch notifications
  useEffect(() => {
    if (!notificationsFetched) {
      fetchNotifications()
    }
  }, [notificationsFetched, fetchNotifications])

  // Go to welcome page, if not visited yet
  if (!isWelcomeVisited) {
    return <Redirect to='/welcome' />
  }

  const searchAPI = query => axios.get(`${process.env.REACT_APP_BACKEND_URL}/locations/search?query=${query}`);
  const searchAPIDebounced = AwesomeDebouncePromise(searchAPI, 500);

  const handleSearch = async e => {
    const query = e.target.value
    if(query !== ''){
      const result = await searchAPIDebounced(query);
      setHubResult(result.data)
      setDropdownVisible(true)
    } else {
      console.log('Cancelled')
      setDropdownVisible(false)
    }
  }

  return (
    <Page {...controls} dark active={true}>
      {/* Header */}
      <Header onMenuOpen={openMenu} />

      {/* Search Bar */}
      <Page.Section>
        <Search onChange={handleSearch}/>
        <SearchResultList hubResult={hubResult} dropdownVisible={dropdownVisible}/>
      </Page.Section>

      <Page.Section>
        <div className='d-flex flex-column flex-gap-1'>
          <Can perform='dashboard-document-library:view'>
            <Button wide onClick={() => history.push("/documents")} className='mt-3'>
              Document Library
            </Button>
          </Can>
          <Can perform='dashboard-new-incident:view'>
            <Button wide variant='warning' onClick={() => history.push("/actions")}>
              New Report
            </Button>
            <Button wide onClick={() => history.push("/training")}>
              Training
            </Button>
            <Button wide variant='warning' onClick={() => history.push("/new-tasks")}>
              New Task
            </Button>
          </Can>
          
        </div>
      </Page.Section>

      <Can perform='dashboard-portfolio-analytics:view'>
        <div className='mt-4'>
          <MultipleHotelsCard />
        </div>
      </Can>

      <Can perform='dashboard-hotel-analytics:view'>
        <div className='mt-4'>{hotels?.[0] && <HotelCard hotel={hotels[0]} />}</div>
      </Can>

      <Can perform='dashboard-tasks:view'>
        <TasksToday />
      </Can>

      <Can perform='dashboard-summary:view'>
        <DashboardSummary />
      </Can>
    </Page>
  )
}

const mapDispatchToProps = dispatch => ({
  fetchWorkflows: () => dispatch(workflowActions.fetchWorkflows()),
  fetchHotels: () => dispatch(hotelActions.fetchHotels()),
  fetchNotifications: () => dispatch(systemActions.fetchNotifications()),
})

const mapStateToProps = state => ({
  isWelcomeVisited: state.user.isWelcomeVisited,
  workflowsFetched: state.workflows.fetched,
  hotelsFetched: state.hotels.fetched,
  notificationsFetched: state.system.notificationsFetched,
  hotels: state.hotels.list,
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
