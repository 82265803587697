export const capitalise = (string, all = false) => {
  if (typeof string != 'string') {
    console.warn(`Warning: capitalise only accepts string type, ${typeof string} was provided.\nEmpty string was returned.`)
    return ""
  }
  if(all) {
    return string.split(' ').map(word => capitalise(word, false)).join(' ')
  } else {
    return string.charAt(0).toUpperCase() + string.substr(1)
  }
}

export default capitalise