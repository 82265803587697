import React, { useState, useEffect } from "react"
import axios from "axios"
import filterIcon from '../images/icons/filter.svg'
import RightMenu from "../components/RightMenu"
import Page, { useMenu } from '../components/Page'
import Hotel from "../components/Hotel"
import c from "./Hotels.module.scss"
import Toggle from "aius-comp/Toggle"
import { Formik, Field, useField } from "formik"
import Button from "aius-comp/Button"
/* 
  List of hotels assigned to user
*/

const Hotels = props => {
  const [openMenu, controls] = useMenu()
  const [filterOpen, setFilterOpen] = useState(false)
  
  // Options for query parameters
  const [hotels, setHotels] = useState([])
  const [schema, setSchema] = useState([])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations`)
      .then(res => {
        setHotels(res.data.locations)
      })
      .catch(e => {
        console.error(e)
      })
  }, [])

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/locations/schema`)
      .then(res => {
        setSchema(res.data?.data.filter(i => i.property_schema_category_id === 6 && i.type === 'Boolean'))
      })
      .catch(e => {
        console.error(e)
      })
  }, [])


  // Aggregate by group
  const groupedHotels = hotels.reduce((acc, hotel) => {
    const { categories, ...rest } = hotel
    if (!categories) {
      const noGroup = 'Ungrouped'
      if (!acc[noGroup]) {
        acc[noGroup] = []
      }
      acc[noGroup].push(rest)
    } else {
      for (const { name } of categories) {
        if (!acc[name]) {
          acc[name] = []
        }
        acc[name].push(rest)
      }
    }
    return acc
  }, {})

  const groups = Object.keys(groupedHotels) ?? []

  const handleSearch = async (values, { setSubmitting }) => {
    const { details } = values
    setSubmitting(true)
    const dataArray = Object.keys(details).filter(val => details[val].value).map(val => details[val].property_schema_id);
    axios
    .get(`${process.env.REACT_APP_BACKEND_URL}/locations`, {params : {property_schema_ids:dataArray}})
    .then(res => {
      setHotels(res.data.locations)
    })
    .catch(e => {
      console.error(e)
    })
    setSubmitting(false)
    setFilterOpen(false)
  }

  return (
    <Page {...controls} active={true}>
      <Page.Top onMenuOpen={openMenu}>Hotels</Page.Top>
      {/* Controls */}
      <div className={c.controls}>
        <div>
          <img src={filterIcon} onClick={() => setFilterOpen(true)} alt=''/>
        </div>
      </div>

      {/* Hotel Groups */}
      <div>
        {hotels.length > 0 ? (
          <>
            {groups.map(group => (
              <div className={c.section} key={group}>
                <div className={c.group}>{group}</div>
                {groupedHotels[group]?.map(hotel => (
                  <Hotel id={hotel.id} key={hotel.id} name={hotel.name} city={hotel.city} coverImageURL={hotel.coverImageURL} />
                ))}
              </div>
            ))}
          </>
        ) : (
          <div className='d-flex justify-content-center h-100'>
            <h3>No hotels</h3>
          </div>
        )}
        {/* Filter Menu */}
        <RightMenu isOpen={filterOpen} close={() => setFilterOpen(false)}>
        <RightMenu.Section>
          <div className='d-flex justify-content-center h-100'>
            <h2>Search By Feature</h2>
          </div>
          <hr />
          <div className='d-flex flex-column flex-gap-2'>
          <Formik
              enableReinitialize
              initialValues={{
                
              }}
              onSubmit={handleSearch}
            >
            {({ values, errors, handleSubmit, setFieldValue }) => (
            <>
              {schema.map( eachSchema => (
                <div key={eachSchema.id} className={c.buttonlist}>
                  <div className={c.iconContainer}>
                    <div className={c.icon}>
                      <i className={eachSchema.iconURL}></i>
                      <div className={c.text}>{eachSchema.label}</div>
                    </div>
                    <div>
                      <DetailElement key={eachSchema.id} detail={eachSchema} />
                    </div>
                  </div>
                  
                </div>
              ))}
              <div className='d-flex justify-content-end pb-4'>
                <Button className='mt-4' onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </>
            )}
              
            </Formik>
          </div>

        </RightMenu.Section>
        </RightMenu>
      </div>
    </Page>
  )
}

const DetailElement = ({ detail }) => {
  // eslint-disable-next-line
  const [field, _ , helpers] = useField(`details.${detail.name}`)
  const { type } = detail
  if (["Boolean"].includes(type)) {
    let val = field?.value?.value;
    return (
      <Field
        name={field.name}
        value={val}
        onChange={toggled =>
          helpers.setValue({
            value: toggled,
            property_schema_id: detail.id,
          })
        }
        as={Toggle}
      />
    )
  }
}

export default Hotels