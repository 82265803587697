import React from 'react'
import cn from 'classnames'
import c from './Bell.module.scss'
import { BsEnvelope } from 'react-icons/bs'

/*
  Notification bell component.
  Displays red dot if active.
*/

const Envelope = ({ count, active = false, className, ...rest }) => {
  const containerClasses = cn(c.container, className)  
  return (
    <div {...rest} className={containerClasses}>
      <BsEnvelope className={c.bell}/>
      { active && <div className={c.dotEnv}>{count}</div> }
    </div>
  )
}

export default Envelope