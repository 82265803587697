import React from 'react'
import c from './CloseHeader.module.scss'
import closeImage from "../images/icons/cancel.svg"

const CloseHeader = props => {
  return (
    <div className={c.header}>
      <div className={c.top}>
        <h2>{props.title}</h2>
        <div>
          <img src={closeImage} className={c.closeImage} onClick={props.onClose} alt=''/>
        </div>
      </div>
      <hr />
    </div>
  )
}

export default CloseHeader
